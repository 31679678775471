const { MYADVISOR } = require("helpers/backend_url_helper")
const {
  MYADVISORSUCCESS,
  MYADVISORERROR,
  ADVISORLIST,
  ADVISORLISTSUCCESS,
  ADVISORLISTERROR,
  SAVEDADVISORSUCCESS,
  SAVEDADVISORERROR,
  SAVEADVISORSUCCESS,
  SAVEADVISORERROR,
  DELETESAVEADVISORSUCCESS,
  DELETESAVEADVISORERROR,
  FILTERADVISORSUCCESS,
  FILTERADVISORERROR,
  MEETINGREQUESTSUCCESS,
  MEETINGREQUESTError,
  JOINREQUESTSUCCESS,
  JOINREQUESTError,
  REQUESTLIST,
  REQUESTLISTSUCCESS,
  REQUESTACTIONError,
  REQUESTLISTError,
  REQUESTACTIONSUCCESS,
  SEARCHMYADVISOR,
  SEARCHMYADVISORSUCCESS,
  SEARCHMYADVISORERROR,
  SEARCHSAVEDADVISORSUCCESS,
  SEARCHSAVEDADVISORERROR,
  BECOME_ADVISOR_SUCCESS,
  BECOME_ADVISOR_FAIL,
} = require("./actionTypes")

const initialState = {
  error: "",
  loading: false,
  myList: [],
  saved: [],
  advisors: [],
  success: "",
  request: [],
  become_advisor:null
}

const MyAdvisor = (state = initialState, action) => {
  switch (action.type) {
    case MYADVISORSUCCESS:
      state = {
        ...state,
        myList: action.payload,
      }
    case MYADVISORERROR:
      state = {
        ...state,
        error: action.payload,
      }
    case SEARCHMYADVISORSUCCESS:
      state = {
        ...state,
        myList: action.payload,
      }
    case SEARCHMYADVISORERROR:
      state = {
        ...state,
        error: action.payload,
      }
    case SEARCHSAVEDADVISORSUCCESS:
      state = {
        ...state,
        saved: action.payload,
      }
    case SEARCHSAVEDADVISORERROR:
      state = {
        ...state,
        error: action.payload,
      }
    case ADVISORLISTSUCCESS:
      state = {
        ...state,
        advisors: action.payload,
      }
    case ADVISORLISTERROR:
      state = {
        ...state,
        error: action.payload,
      }
    case SAVEDADVISORSUCCESS:
      state = {
        ...state,
        saved: action.payload,
      }
    case SAVEDADVISORERROR:
      state = {
        ...state,
        error: action.payload,
      }
    case SAVEADVISORSUCCESS:
      state = {
        ...state,
        success: action.payload,
      }
    case SAVEADVISORERROR:
      state = {
        ...state,
        error: action.payload,
      }
    case DELETESAVEADVISORSUCCESS:
      state = {
        ...state,
        success: action.payload,
      }
    case DELETESAVEADVISORERROR:
      state = {
        ...state,
        error: action.payload,
      }
    case FILTERADVISORSUCCESS:
      state = {
        ...state,
        advisors: action.payload,
      }
    case FILTERADVISORERROR:
      state = {
        ...state,
        error: action.payload,
      }
    case MEETINGREQUESTSUCCESS:
      state = {
        ...state,
        success: action.payload,
      }
    case MEETINGREQUESTError:
      state = {
        ...state,
        error: action.payload,
      }
    case JOINREQUESTSUCCESS:
      state = {
        ...state,
        success: action.payload,
      }
    case JOINREQUESTError:
      state = {
        ...state,
        error: action.payload,
      }
    case REQUESTLISTSUCCESS:
      state = {
        ...state,
        request: action.payload,
      }
    case REQUESTLISTError:
      state = {
        ...state,
        error: action.payload,
      }
    case REQUESTACTIONSUCCESS:
      state = {
        ...state,
        success: action.payload,
      }
    case REQUESTACTIONError:
      state = {
        ...state,
        error: action.payload,
      }
      case BECOME_ADVISOR_SUCCESS:
        state = {
          ...state,
          become_advisor: action.payload,
        }
      case BECOME_ADVISOR_FAIL:
        state = {
          ...state,
          error: action.payload,
        }
  }
  return state
}

export default MyAdvisor
