import {
  myAdvisorList,
  advisorsList,
  savedAdvisor,
  saveAdvisor,
  deleteSavedAdvisor,
  filterAdvisor,
  meetingRequestToAdvisor,
  addRquest,
  requestList,
  requestAction,
  searchMyAdvisor,
  searchSavedAdvisorList,
  requestSent,
  becomeAnAdvisor,
} from "helpers/backend_helper"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { call, put, takeEvery } from "redux-saga/effects"

import {
  myAdvisorSuccess,
  myAdvisorError,
  advisorListSuccess,
  advisorListError,
  SavedAdvisorError,
  SavedAdvisorSuccess,
  SaveAdvisorError,
  SaveAdvisorSuccess,
  deleteSavedAdvisorError,
  deleteSavedAdvisorSuccess,
  filterAdvisorError,
  filterAdvisorSuccess,
  meetingRequestError,
  meetingRequestSuccess,
  joinRequestSuccess,
  joinRequestError,
  requestActionSuccess,
  requestActionError,
  requestListSuccess,
  requestListError,
  searchMyAdvisorSuccess,
  searchMyAdvisorError,
  requestSentSuccess,
  requestSentError,
  becomeAdvisorSuccess,
  becomeAdvisorError
} from "./actions"
import {
  ADVISORLIST,
  DELETESAVEADVISOR,
  FILTERADVISOR,
  JOINREQUEST,
  MEETINGREQUEST,
  MYADVISOR,
  REQUESTACTION,
  REQUESTLIST,
  REQUEST_SENT,
  SAVEADVISOR,
  SAVEDADVISOR,
  SEARCHMYADVISOR,
  SEARCHSAVEDADVISOR,
  BECOME_ADVISOR,
} from "./actionTypes"

function* myAdvisor() {
  try {
    // console.log("HERE")
    const responce = yield call(myAdvisorList)
    if (responce && responce.status) {
      yield put(myAdvisorSuccess(responce.data))
      //   history.push("/my-advisor")
    } else {
      yield put(myAdvisorError(responce.message))
    }
  } catch (error) {
    yield put(myAdvisorError(error))
  }
}

function* advisorList() {
  try {
    const responce = yield call(advisorsList)
    if (responce && responce.status) {
      yield put(advisorListSuccess(responce.data))
    } else {
      yield put(advisorListError(responce.message))
    }
  } catch (error) {
    yield put(advisorListError(error))
  }
}

function* savedAdvisors() {
  try {
    const responce = yield call(savedAdvisor)
    if (responce && responce.status) {
      yield put(SavedAdvisorSuccess(responce.data))
    } else {
      yield put(SavedAdvisorError(responce.message))
    }
  } catch (err) {
    yield put(SavedAdvisorError(err))
  }
}

function* saveAdvisors({ payload: data }) {
  try {
    const responce = yield call(saveAdvisor, data)
    console.log(data, responce)
    if (responce && responce.status) {
      const resp = yield call(advisorsList)
      if (resp && resp.status) {
        toast.success(responce.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        yield put(advisorListSuccess(resp.data))
        // yield put(SaveAdvisorSuccess(responce.message))
      } else {
        toast.danger(responce.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        yield put(advisorListError(resp.message))
      }
    } else {
      yield put(SaveAdvisorError(responce.message))
    }
  } catch (err) {
    yield put(SaveAdvisorError(err))
  }
}

function* deleteSavedAdv({ payload: data }) {
  try {
    const responce = yield call(deleteSavedAdvisor, data)
    if (responce && responce.status) {
      toast.success(responce.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      const resp = yield call(advisorsList)
      if (resp && resp.status) {
        yield put(advisorListSuccess(resp.data))
        // yield put(deleteSavedAdvisorSuccess(responce.message))
      } else {
        yield put(advisorListError(resp.message))
      }
    } else {
      yield put(deleteSavedAdvisorError(responce.message))
    }
  } catch (error) {
    yield put(deleteSavedAdvisorError(error))
  }
}

function* filterAdvisorData({ payload: data }) {
  try {
    const responce = yield call(filterAdvisor, data)
    if (responce && responce.status) {
      yield put(filterAdvisorSuccess(responce.data))
    } else {
      yield put(filterAdvisorError(responce.message))
    }
  } catch (error) {
    yield put(filterAdvisorError(error))
  }
}

function* meetingReqests({ payload: id }) {
  try {
    const responce = yield call(meetingRequestToAdvisor, id)
    // const resp = yield call(advisorsList)
    // const rest = yield call(savedAdvisor)

    if (responce && responce.status) {
      toast.success(responce.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      yield put(meetingRequestSuccess(responce.message))
      // yield put(advisorListSuccess(resp.data))
      // yield put(SavedAdvisorSuccess(rest.data))
    } else {
      yield put(meetingRequestError(responce.message))
    }
  } catch (error) {
    yield put(meetingRequestError(error))
  }
}

function* addRequest({ payload: data }) {
  try {
    console.log(data)
    const responce = yield call(addRquest, data)
    if (responce && responce.status) {
      toast.success(responce.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      yield put(joinRequestSuccess(responce.message))

      if (data.search == 1) {
        const resp = yield call(advisorsList)
        if (resp && resp.status) {
          yield put(advisorListSuccess(resp.data))
        } else {
          yield put(advisorListError(resp.message))
        }
      } else {
        const responce = yield call(savedAdvisor)
        if (responce && responce.status) {
          yield put(SavedAdvisorSuccess(responce.data))
        } else {
          yield put(SavedAdvisorError(responce.message))
        }
      }
    } else {
      yield put(joinRequestError(responce.message))
    }
  } catch (error) {
    yield put(joinRequestError(error))
  }
}

function* requestListData() {
  try {
    const responce = yield call(requestList)
    if (responce && responce.status) {
      yield put(requestListSuccess(responce.data))
    } else {
      yield put(requestListError(responce.message))
    }
  } catch (error) {
    yield put(requestListError(error))
  }
}

function* requestSentData() {
  try {
    const responce = yield call(requestSent)
    if (responce && responce.status) {
      yield put(requestSentSuccess(responce.data))
    } else {
      yield put(requestSentError(responce.message))
    }
  } catch (error) {
    yield put(requestSentError(error))
  }
}

function* requestActionData({ payload: data }) {
  try {
    const responce = yield call(requestAction, data)
    if (responce && responce.status) {
      toast.success(responce.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      const resp = yield call(requestList)
      if (resp && resp.status) {
        yield put(requestListSuccess(resp.data))
      } else {
        yield put(requestListError(resp.message))
      }
      yield put(requestActionSuccess(responce.message))
    } else {
      yield put(requestActionError(responce.message))
    }
  } catch (error) {
    yield put(requestActionError(error))
  }
}

function* searchMyAdvisorList({ payload: data }) {
  try {
    const responce = yield call(searchMyAdvisor, data)
    if (responce && responce.status) {
      yield put(searchMyAdvisorSuccess(responce.data))
    } else {
      yield put(searchMyAdvisorError(responce.message))
    }
  } catch (error) {
    yield put(searchMyAdvisorError(error))
  }
}

function* searchSavedAdvisor({ payload: data }) {
  try {
    const responce = yield call(searchSavedAdvisorList, data)
    if (responce && responce.status) {
      yield put(searchMyAdvisorSuccess(responce.data))
    } else {
      yield put(searchMyAdvisorError(responce.message))
    }
  } catch (error) {
    yield put(searchMyAdvisorError(error))
  }
}

function* becomeAdvisor() {
  try {
    const responce = yield call(becomeAnAdvisor)
    console.log('test', responce)
    if (responce && responce.status) {
      yield put(becomeAdvisorSuccess(responce))
    } else {
      yield put(becomeAdvisorError(responce))
    }
  } catch (error) {
    yield put(becomeAdvisorError(error))
  }
}

function* myAdvisorSaga() {
  yield takeEvery(MYADVISOR, myAdvisor)
  yield takeEvery(ADVISORLIST, advisorList)
  yield takeEvery(SAVEDADVISOR, savedAdvisors)
  yield takeEvery(SAVEADVISOR, saveAdvisors)
  yield takeEvery(DELETESAVEADVISOR, deleteSavedAdv)
  yield takeEvery(FILTERADVISOR, filterAdvisorData)
  yield takeEvery(MEETINGREQUEST, meetingReqests)
  yield takeEvery(JOINREQUEST, addRequest)
  yield takeEvery(REQUESTLIST, requestListData)
  yield takeEvery(REQUEST_SENT,requestSentData)
  yield takeEvery(REQUESTACTION, requestActionData)
  yield takeEvery(SEARCHMYADVISOR, searchMyAdvisorList)
  yield takeEvery(SEARCHSAVEDADVISOR, searchSavedAdvisor)
  yield takeEvery(BECOME_ADVISOR, becomeAdvisor)
}

export default myAdvisorSaga
