import {
  ADD_NEW_INDUSTRY,
  ADD_NEW_INDUSTRY_SUCCESS,
  ADD_SNEW_INDUSTRY_ERROR,
  DELETE_INDUSTRY,
  DELETE_INDUSTRY_ERROR,
  DELETE_INDUSTRY_USCCESS,
  EDIT_INDUSTRY,
  EDIT_INDUSTRY_ERROR,
  EDIT_INDUSTRY_SUCCESS,
  INDUSTRY_LIST,
  INDUSTRY_LIST_ERROR,
  INDUSTRY_LIST_SUCCESS,
} from "./actionTypes"

export const getIndustry = () => ({
  type: INDUSTRY_LIST,
})

export const getIndustrySucceess = data => ({
  type: INDUSTRY_LIST_SUCCESS,
  payload: data,
})

export const getIndustryError = error => ({
  type: INDUSTRY_LIST_ERROR,
  payload: error,
})

export const addIndustry = data => (
  console.log(data),
  {
    type: ADD_NEW_INDUSTRY,
    payload: data,
  }
)

export const addIndustrySuccess = data => ({
  type: ADD_NEW_INDUSTRY_SUCCESS,
  payload: data,
})

export const addIndustryError = error => ({
  type: ADD_SNEW_INDUSTRY_ERROR,
  payload: error,
})

export const editIndustry = data => ({
  type: EDIT_INDUSTRY,
  payload: data,
})

export const editIndustrySuccess = data => ({
  type: EDIT_INDUSTRY_SUCCESS,
  payload: data,
})

export const editIndustryError = error => ({
  type: EDIT_INDUSTRY_ERROR,
  payload: error,
})

export const deleteIndustry = data => ({
  type: DELETE_INDUSTRY,
  payload: data,
})

export const deleteIndustrySuccess = data => ({
  type: DELETE_INDUSTRY_USCCESS,
  payload: data,
})

export const deleteIndustryError = error => ({
  type: DELETE_INDUSTRY_ERROR,
  payload: error,
})
