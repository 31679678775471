import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  REGISTER_USER_INFO,
  REGISTER_USER_INFO_SUCCESSFUL,
  REGISTER_USER_INFO_FAILED
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}


export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const registerUserInfo = (user, history) => {
  return {
    type: REGISTER_USER_INFO,
    payload: {user, history}
  }
}
export const registerUserInfoSuccessful = user => {
  return {
    type: REGISTER_USER_INFO_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserInfoFailed = user => {
  return {
    type: REGISTER_USER_INFO_FAILED,
    payload: user,
  }
}


