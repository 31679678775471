import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
// base url
import {API_URL} from '../../../helpers/api_helper'
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { userProfile } from "store/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  const [role, setRole] = useState(1)
  const [status, setStatus] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userProfile(props.history))
  }, [])

  const { details } = useSelector(state => ({
    details: state.Users.details,
  }))

  // if (details) {
  //   setusername(details.first_name + " " + details.last_name)
  //   setRole(details.is_advisory)
  //   setStatus(details.advisor_status)
  // }

  return (
    <React.Fragment>
      {/* <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {details.first_name && details.last_name
              ? details.first_name + " " + details.last_name
              : username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          {details &&
          details.is_advisory &&
          details.is_advisory == 1 &&
          details.advisor_status == 1 ? (
            <>
              <DropdownItem tag="a" href="/request-list">
                {" "}
                <i className="bx bx-file font-size-16 align-middle me-1" />
                {props.t("Your Requests")}{" "}
              </DropdownItem>
              <div className="dropdown-divider" />
            </>
          ) : null}

          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown> */}
      <div className="user-img">
        <div className="dropdown">
          <div
            className="ic-avatar d-flex align-items-center dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {
              details.profile_picture ?
              <img src={API_URL+`profilePicture/${details.profile_picture}`} alt="" />
              :
              <img src={user1} alt="" />
            }
            
            <span className="ic-text">
              {details.first_name && details.last_name
                ? details.first_name + " " + details.last_name
                : username}
            </span>
            {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
            <i className="mdi mdi-chevron-down"></i>
          </div>
          <div className="dropdown-menu dropdown-menu-end">
            <Link className="dropdown-item" to="/profile">
              <i className="mdi mdi-account-outline ms-0 me-1"></i> Profile
            </Link>
            <Link className="dropdown-item" to="/dashboard">
            <i className="mdi mdi-home-outline ms-0 me-1"></i> Dashboard
            </Link>
            <div className="dropdown-divider"></div>
            {details &&
            details.is_advisory &&
            details.is_advisory == 1 &&
            details.advisor_status == 1 ? (
              <>
                <Link className="dropdown-item" to="/request-list">
                  {" "}
                  <i className="bx bx-file font-size-16 align-middle me-1" />
                  {props.t("Your Requests")}{" "}
                </Link>
                <div className="dropdown-divider" />
              </>
            ) : null}
            <Link to="/logout" className="dropdown-item text-danger" >
              <i className="mdi mdi-power ms-0 me-1"></i> Logout
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
