import axios from "axios"

//apply base url for axios
export const API_URL = "https://api.advisory.ph/"
//export const API_URL = "http://172.105.39.174:8001/public/"

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )

// Request interceptor for API calls

axiosApi.interceptors.request.use(
  async config => {
    const token = localStorage.getItem("authtoken")
    config.headers.authorization = `Bearer ${token}`
    return config
  },
  error => {
    Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function postFormData(url, data, config = {
  headers: {
    "Content-Type": "multipart/form-data"
  }
}) {
  return axiosApi
    .post(url, data, config)
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
