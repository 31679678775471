import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

// base url
import {API_URL} from "../../helpers/api_helper"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData, requestList, userProfile, requestSent, becomeAdvisor } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import avatarImg from "../../assets/images/users/avatar-1.jpg"


const Dashboard = props => {
  const dispatch = useDispatch()
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [role, setRole] = useState("")
  const [isAdvisory, setIsAdvisory] = useState(0)
  const [becomeAdvisorStatus, setBecomeAdvisorStatus] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(true);

    useEffect(() => {
        // ref.current.recalculate()
        // const obj = JSON.parse(localStorage.getItem("authUser"))
        // setRole(obj.user.role_id)
        // setIsAdvisory(obj.user.is_advisory)
        dispatch(userProfile(props.history))


        jQuery('.owl-carousel.slider-articles').owlCarousel({
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            items:3,
            //mouseDrag: false,
            //touchDrag: false,
            margin:30,
            nav:true,
            dots:false,
            stagePadding:0,
            autoplay:false,
            autoplayTimeout:2500,
            autoplayHoverPause:true,
            smartSpeed:2500,
            loop: true,
            navText : ["<i class='mdi mdi-arrow-left'></i>","<i class='mdi mdi-arrow-right'></i>"],
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                768:{
                    items:3,
                },
                1000:{
                    items:3
                }
            }
        });
    },[])

  const { chartsData, requestData, details, requestSentData, becomeAdvisorData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
    becomeAdvisorData: state.MyAdvisor.become_advisor,
    details: state.Users.details,
    requestData: state.MyRequestAdvisor.request,
    requestSentData: state.MyRequestAdvisor.request_sent
  }))

  useEffect(()=>{
    setRole(details.role_id)
    setIsAdvisory(details.is_advisory)
  },[details])

  useEffect(()=> {
        setBecomeAdvisorStatus(becomeAdvisorData)
  },[becomeAdvisorData])


  const reports = [
    { title: "Total Users", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Advisors", iconClass: "bx-archive-in", description: "8019" },
    {
      title: "Connected Advisors",
      iconClass: "bx-purchase-tag-alt",
      description: "100",
    },
    {
      title: "Saved Advisor",
      iconClass: "bx-purchase-tag-alt",
      description: "100",
    },
  ]
  const [newReports, setNewReports] = useState(reports)

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(false)
    }, 2000)
    dispatch(requestList())
    dispatch(requestSent())
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Dashboard | Advisory Dashboard"

  useEffect(()=> {
    const updatedReports = reports.map((item,index) => {
      return {
        ...item,
        id:index + 1,
        user: {title: item.title, text: item.description}
      }
    })
    setNewReports(updatedReports)

    //
  },[])


{/* delete profile picture */}
const handleBecomeAdvisor = () => {
    dispatch(becomeAdvisor())
}

  return (
    <React.Fragment>
       
      <div className="dashaboard-content ">
            <div className="dashaboard-inner">
                <div className="sec-welcome">
                    <div className="card-section-title mb-3">
                        <h2>Welcome Back !</h2>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-3 mb-4">
                            <div className="card-column card mb-0 bg-white h-100 card-illustration ">
                                <div className="card-column-header">
                                    <img draggable="false" src="images/card-banner.png" alt="" />
                                </div>
                                <div className="card-column-body">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="user-auth text-center">
                                            {
                                                details.profile_picture ?
                                                <span><img src={API_URL+`profilePicture/${details.profile_picture}`} alt="" /></span>
                                                :
                                                <span><img src={avatarImg} alt="" /></span>
                                            }
                                            
                                            <p> {details.first_name} {details.last_name} </p>
                                            <Link to="/profile" className="btn btn-primary btn-fill">View Profile <i
                                                className="mdi mdi-arrow-right"></i></Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3 mb-4">
                            <div className="card-column card mb-0 bg-white h-100 py-4 px-4">
                                <div className="card-block">
                                    <div className="d-flex align-items-center">
                                        <div className="card-text">
                                            <span className="card--block-img">
                                                <img src="images/card-block-icon-1.svg" alt="" />
                                            </span>
                                            <h4>Advisors Database</h4>
                                            <p>1000</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3 mb-4">
                            <div className="card-column card mb-0 bg-white h-100 py-4 px-4">
                                <div className="card-block">
                                    <div className="d-flex align-items-center">
                                        <div className="card-text">
                                            <span className="card--block-img">
                                                <img src="images/card-block-icon-2.svg" alt="" />
                                            </span>
                                            <h4>Saved Profiles</h4>
                                            <p>6000</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3 mb-4">
                            <div className="card-column card mb-0 bg-white h-100 py-4 px-4">
                                <div className="card-block">
                                    <div className="d-flex align-items-center">
                                        <div className="card-text">
                                            <span className="card--block-img">
                                                <img src="images/card-block-icon-3.svg" alt="" />
                                            </span>
                                            <h4>Your Board</h4>
                                            <p>5500</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sec-request">
                    <div className="row">
                        <div className="col-xl-6 mb-4">
                            <div className="card-white bg-white h-100 card mb-0">
                                <div className="card-section-title">
                                    <h2>Requests Sent</h2>
                                </div>
                                <div className="card-white-header p-3">
                                    <div className="d-flex align-items-center">
                                        <div className="ms-auto header-action">
                                            <a href="" className="action-link">View All <i className="mdi mdi-chevron-double-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-white-body p-3">
                                    <div className="table-responsive">
                                        <table className="table table-dashboard table-requests-sent m-0">
                                            <tbody>
                                                {
                                                    requestSentData && requestSentData.length > 0 ?
                                                    requestSentData.map((req,i)=>{
                                                        return(
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                       {
                                                                            req.requested_to_user.profile_picture ? 
                                                                            <img src={req.requested_to_user.profile_picture} alt="" />
                                                                            :
                                                                            <img src="images/avatar-1.jpg" alt="" />
                                                                        }
                                                                        <div className="flex-1 ms-3">
                                                                            <h6 className="mb-1"><a href="#"> {req.requested_to_user.first_name} {req.requested_to_user.last_name} </a></h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <p>{req.requested_to_user.email}</p>
                                                                </td>
                                                                <td>
                                                                <div className="d-flex justify-content-end">
                                                                        <a href="" className="btn btn-outline">Cancel</a>
                                                                </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                         <td>
                                                          <p>No Data Founnd</p>
                                                        </td>
                                                    </tr>
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            role !== 1 &&
                        <div className="col-xl-6 mb-4">
                            {
                                (role !== 1 && isAdvisory == 0) ? 
                                <div className="card-white bg-white h-100 card mb-0 position-relative">
                                    <div className="card-section-title">
                                        <h2>Requests Received</h2>
                                    </div>
                                    <div className="card-white-body  p-3">
                                        <button onClick={handleBecomeAdvisor} className="btn btn-primary btn-fill position-ab">Become an Advisor <i className="mdi mdi-arrow-right"></i></button>
                                    </div>
                                </div>
                                :
                                <>
                                <div className="card-white bg-white h-100 card mb-0">
                                    <div className="card-section-title">
                                        <h2>Join Request</h2>
                                    </div>
                                    <div className="card-white-header p-3">
                                    <div className="d-flex align-items-center">
                                        <div className="ms-auto header-action">
                                            <Link to="/request-list" className="action-link">View All <i className="mdi mdi-chevron-double-right"></i></Link>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card-white-body p-3">
                                        <div className="table-responsive">
                                            <table className="table table-dashboard table-requests-sent m-0">
                                                <tbody>
                                                    {
                                                        requestData && requestData.length > 0 ?
                                                         requestData.map((req,i)=> {
                                                            return(
                                                                <tr key={i}>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {
                                                                            req.requested_by_user.profile_picture ? 
                                                                            <img src={req.requested_by_user.profile_picture} alt="" />
                                                                            :
                                                                            <img src="images/avatar-1.jpg" alt="" />
                                                                        }
                                                                       
                                                                        <div className="flex-1 ms-3">
                                                                            <h6 className="mb-1"><a href="#"> {req.requested_by_user.first_name} {req.requested_by_user.last_name}</a></h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <p>{req.requested_by_user.email}</p>
                                                                </td>
                                                                </tr>
                                                            )
                                                         })
                                                         :
                                                         null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>    
                                </>
                            }
                            
                        </div>
                        }
                    </div>
               </div>
                <div className="sec-latest-articles">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-section-title mb-3">
                                <h2>Latest articles</h2>
                            </div>
                            <div className="owl-carousel owl-theme slider-articles">
                                <div className="item">
                                    <div className="card-articles">
                                        <div className="articles-img">
                                            <img src="images/slider-articles-img-1.jpg" alt="" />
                                        </div>
                                        <div className="post-info">02 jan. 2022</div>
                                        <p>Collaboratively expedite pandemic 
                                            services for turnkey outsourcing.</p>
                                            <a href="" className="action-link">Read More<i className="mdi mdi-chevron-double-right"></i></a>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="card-articles">
                                        <div className="articles-img">
                                            <img src="images/slider-articles-img-2.jpg" alt="" />
                                        </div>
                                        <div className="post-info">02 jan. 2022</div>
                                        <p>Intrinsicly evolve emerging architectures 
                                            after standardized convergence.</p>
                                            <a href="" className="action-link">Read More<i className="mdi mdi-chevron-double-right"></i></a>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="card-articles">
                                        <div className="articles-img">
                                            <img src="images/slider-articles-img-3.jpg" alt="" />
                                        </div>
                                        <div className="post-info">02 jan. 2022</div>
                                        <p>Energistically communicate B2C 
                                            manufactured products</p>
                                            <a href="" className="action-link">Read More<i className="mdi mdi-chevron-double-right"></i></a>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="card-articles">
                                        <div className="articles-img">
                                            <img src="images/slider-articles-img-1.jpg" alt="" />
                                        </div>
                                        <div className="post-info">02 jan. 2022</div>
                                        <p>Collaboratively expedite pandemic 
                                            services for turnkey outsourcing.</p>
                                            <a href="" className="action-link">Read More<i className="mdi mdi-chevron-double-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {becomeAdvisorStatus?.status ? 
                    <Modal isOpen={isModalOpen} toggle={()=>setIsModalOpen(false)} centered>
                      <ModalBody>
                        <div className="content-area-modal text-center">
                                  <img src="images/icon-success.svg" alt="" className="img" />
                                  <h2>Success</h2>
                                  <p> {becomeAdvisorStatus?.message} </p>
                                  <button type="button" className="btn btn-primary btn-green" onClick={()=>{
                                    setIsModalOpen(false)
                                    window.location.reload()
                                  }}>OK</button>
                                </div>
                      </ModalBody>
                    </Modal>
            : null}
            <footer className="footer">
                <div className="col-md-12">
                    <div className="">
                        <p className="m-0">2020 © Advisory.</p>
                    </div>
                </div>
            </footer>
      </div>
        
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
