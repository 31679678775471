import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const [role, setRole] = useState("")
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
    const obj = JSON.parse(localStorage.getItem("authUser"))
    setRole(obj.user.role_id)
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                {/* <span className="badge rounded-pill bg-info float-end">
                  04
                </span> */}
                <span>{props.t("Dashboards")}</span>
              </Link>
              {/* <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">{props.t("Default")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-saas">{props.t("Saas")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-crypto">{props.t("Crypto")}</Link>
                </li>
                <li>
                  <Link to="/blog">{props.t("Blog")}</Link>
                </li>
              </ul> */}
            </li>

            <li className="menu-title">{props.t("Advisors")}</li>

            <li>
              <Link to="/my-advisors">
                <i className="bx bx-calendar"></i>
                <span>{props.t("My Advisors")}</span>
              </Link>
            </li>

            <li>
              <Link to="/search-advisor">
                <i className="bx bx-chat"></i>
                <span>{props.t("Search")}</span>
              </Link>
            </li>
            <li>
              <Link to="/saved-advisor">
                <i className="bx bx-file"></i>
                <span>{props.t("Saved Advisors")}</span>
              </Link>
            </li>

            <li className="menu-title">Profile</li>
            <li>
              <Link to="/profile">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("My profile")}</span>
              </Link>
            </li>
            {role == 1 ? (
              <>
                <li className="menu-title">Admin</li>
                <li>
                  <Link to="/users-list">
                    <i className="bx bx-user"></i>
                    <span>{props.t("Users List")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/skills-list">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Skills")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/industry-list">
                    <i className="bx bx-cog"></i>
                    <span>{props.t("Industry")}</span>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
