import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"
import { useLocation } from 'react-router-dom';
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUserInfo, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

const RegisterInfo = props => {
  //meta title
  document.title = "What are you looking for"
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const id = searchParams.get('id')

  const dispatch = useDispatch()

  const [rolesValue, setRolesValue] = useState(null);
  const [avatar, setAvatar] = useState(null)
  const [avatarURL, setAvatarURL] = useState(null)

  const handleImageChange =(event)=> {
    const file = event.target.files[0];
    const avatarURL = URL.createObjectURL(file)
    setAvatarURL(avatarURL)
    setAvatar(file)
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      avatar: "",
      id:id,
      email: email,
      currentCompany: "",
      currentDesignation: "",
      phone:"",
      roles: "",
    },
    validationSchema: Yup.object({
      //avatar: Yup.string().required("Please Choose Profile Picture"),
      //name: Yup.string().required("Please Enter Your Name"),
      currentCompany: Yup.string().required("Please Enter Current Company Name"),
      currentDesignation: Yup.string().required("Please Enter Current Designation"),
      //roles: Yup.string().required("Please Select The Role"),
    }),
    onSubmit: values => {
      const { id, email, currentCompany, currentDesignation, phone, roles } = values;
      const formData = new FormData();
    
      formData.append('avatar', avatar);
      formData.append('id', id);
      formData.append('email', email);
      formData.append('currentCompany', currentCompany);
      formData.append('currentDesignation', currentDesignation);
      formData.append('phone', phone)
      formData.append('roles', roles);
      dispatch(registerUserInfo(formData, props.history));
    },
  })


  const { user, registrationInfoError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationInfoError: state.Account.registrationInfoError,
    loading: state.Account.loading,
  }))

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  const handleRadioChange = (event) => {
    setRolesValue(event.target.value);
    validation.setFieldValue('roles', event.target.value);
  };

  return (
    <React.Fragment>
      <div className="form-signin d-flex align-items-center justify-content-center min-vh-100 mx-auto flex-column">
        <div className="card-advisor-main">
          <div className="shadow">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                // return false
              }}
            >
              {registrationInfoError ? (
                        <Alert color="danger">{registrationInfoError}</Alert>
              ) : null}
              <div className="card-inner-block d-flex flex-wrap">
                <div className="card-aside">
                  <div className="userpicture">
                    <div className="upload-picture">
                      {avatarURL ?
                        <img src={avatarURL} alt="avatar" />
                        : 
                        <img src="images/user-bg-auth.png" alt="" />
                      }
                      <a href="javascript:void(0)" className="btnedit">
                        <i className="mdi mdi-pencil"></i>
                        <input
                          type="file"
                          name="avatar"
                          id="avatar"
                          accept="image/*"
                          // onChange={validation.handleChange}
                          // onBlur={validation.handleBlur}
                          // value={validation.values.avatar || ""}
                          // invalid={
                          //   validation.touched.avatar && validation.errors.avatar
                          //     ? true
                          //     : false
                          // }
                          onChange={handleImageChange}
                        />
                        {/* {validation.touched.avatar && validation.errors.avatar ? (
                          <FormFeedback type="invalid">
                            {validation.errors.avatar}
                          </FormFeedback>
                        ) : null} */}
                      </a>
                    </div>
                  </div>

                  <div className="form-card">
                    <div className="mb-3">
                      <div className="position-relative input-single">
                        <label className="form-label">Email</label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          readOnly
                          value={email}
                          // onChange={validation.handleChange}
                          // onBlur={validation.handleBlur}
                          // value={validation.values.name || ""}
                          // invalid={
                          //   validation.touched.name && validation.errors.name
                          //     ? true
                          //     : false
                          // }
                        />
                        {/* {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null} */}
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="position-relative input-single">
                        <label className="form-label">Current Company*</label>
                        <Input
                          id="currentCompany"
                          name="currentCompany"
                          type="text"
                          className="form-control"
                          placeholder="Microsoft Corporation"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.currentCompany || ""}
                          invalid={
                            validation.touched.currentCompany && validation.errors.currentCompany
                              ? true
                              : false
                          }
                        />
                        {validation.touched.currentCompany && validation.errors.currentCompany ? (
                          <FormFeedback type="invalid">
                            {validation.errors.currentCompany}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="position-relative input-single">
                        <label className="form-label">Current designation*</label>
                        <Input
                          id="currentDesignation"
                          name="currentDesignation"
                          type="text"
                          className="form-control"
                          placeholder="Software Engineer"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.currentDesignation || ""}
                          invalid={
                            validation.touched.currentDesignation && validation.errors.currentDesignation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.currentDesignation && validation.errors.currentDesignation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.currentDesignation}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="position-relative input-single">
                        <label className="form-label">Phone Number</label>
                          <div className="input-group flex-nowrap">
                            <span className="lang-selectpicker">
                              <select className="selectpicker" data-width="fit">
                                <option data-content='<span className="flag-icon flag-icon-us"></span>'></option>
                                <option data-content='<span className="flag-icon flag-icon-es"></span>'></option>
                              </select>
                            </span>
                            <input 
                             id="phone"
                             name="phone"
                             type="number"
                             className="form-control"
                             placeholder="" 
                             onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone || ""}
                              invalid={
                                validation.touched.phone && validation.errors.phone
                                  ? true
                                  : false
                              }
                             />
                          </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="card-content">
                  <div className="card-clipboard">
                    <h2>What are you looking for?</h2>
                    <div className="card-option" role="group">
                      <label className="radio-container">Looking for advisory
                        <input type="radio" name="radio" value="1" onChange={handleRadioChange}  />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-container">Looking to become an advisor
                        <input type="radio" name="radio" value="2" onChange={handleRadioChange}  />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-container m-0">Both, looking for and becoming an advisor
                        <input type="radio" name="radio" value="3" onChange={handleRadioChange}  />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <button type="submit" className="btn btn-primary btn-fill">Continue</button>
                  </div>
                </div>
              </div>

            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RegisterInfo
