export const INDUSTRY_LIST = "INDUSTRY_LIST"
export const INDUSTRY_LIST_SUCCESS = "INDUSTRY_LIST_SUCCESS"
export const INDUSTRY_LIST_ERROR = "INDUSTRY_LIST_ERROR"

export const ADD_NEW_INDUSTRY = "ADD_NEW_INDUSTRY"
export const ADD_NEW_INDUSTRY_SUCCESS = "ADD_NEW_INDUSTRY_SUCCESS"
export const ADD_SNEW_INDUSTRY_ERROR = "ADD_NEW_INDUSTRY_ERROR"

export const EDIT_INDUSTRY = "EDIT_INDUSTRY"
export const EDIT_INDUSTRY_SUCCESS = "EDIT_INDUSTRY_SUCCESS"
export const EDIT_INDUSTRY_ERROR = "EDIT_INDUSTRY_ERROR"

export const DELETE_INDUSTRY = "DELETE_INDUSTRY"
export const DELETE_INDUSTRY_USCCESS = "DELETE_INDUSTRY_SUCCESS"
export const DELETE_INDUSTRY_ERROR = "DELETE_INDUSTRY_ERROR"
