import {
  PROFILE,
  PROFILEERROR,
  PROFILESUCCESS,
  UPDATEPROFILE,
  UPDATEPROFILEERROR,
  UPDATEPROFILESUCCESS,
  USERS_LIST,
  USERS_LIST_ERROR,
  USERS_LIST_SUCCESS,
  USER_ACTION,
  USER_ACTION_ERROR,
  USER_ACTION_SUCCESS,
  PROFLE_DELETE,
  PROFILE_DELETE_SUCCESS,
  PROFILE_DELETE_ERROR
} from "./actionTypes"

export const usersList = () => ({
  type: USERS_LIST,
})

export const userListSuccess = data => ({
  type: USERS_LIST_SUCCESS,
  payload: data,
})

export const userListError = error => ({
  type: USERS_LIST_ERROR,
  payload: error,
})

export const usersAction = data => ({
  type: USER_ACTION,
  payload: data,
})

export const usersActionSucces = msg => ({
  type: USER_ACTION_SUCCESS,
  payload: msg,
})

export const userActionError = err => ({
  type: USER_ACTION_ERROR,
  payload: err,
})

export const userProfile = (history) => ({
  type: PROFILE,
  payload: history
})

export const userProfileSuccess = data => ({
  type: PROFILESUCCESS,
  payload: data,
})

export const userProfileError = error => ({
  type: PROFILEERROR,
  payload: error,
})

export const profileUpdate = data => ({
  type: UPDATEPROFILE,
  payload: data,
})

export const profileUpdateSuccess = data => ({
  type: UPDATEPROFILESUCCESS,
  payload: data,
})

export const profileUpdateError = error => ({
  type: UPDATEPROFILEERROR,
  payload: error,
})

export const profileDelete = () => ({
  type: PROFLE_DELETE
})

export const profileDeleteSuccess = data => ({
  type: PROFILE_DELETE_SUCCESS,
  payload: data,
})

export const profileDeleteError = error => ({
  type: PROFILE_DELETE_ERROR,
  payload: error,
})