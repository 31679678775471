const {
  SKILL_LIST_SUCCESS,
  ADD_NEW_SKILL_SUCCESS,
  SKILL_LIST_ERROR,
  ADD_SNEW_SKILL_ERROR,
  EDIT_SKILL_SUCCESS,
  EDIT_SKILL_ERROR,
  DELETE_SKILL_USCCESS,
  DELETE_SKILL_ERROR,
} = require("./actionTypes")

const INIT_STATE = {
  error: "",
  success: "",
  data: [],
}

const Skills = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SKILL_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case SKILL_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_SKILL_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case ADD_SNEW_SKILL_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case EDIT_SKILL_SUCCESS:
      return { ...state, success: action.payload }
    case EDIT_SKILL_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_SKILL_USCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case DELETE_SKILL_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Skills
