import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const Header = props => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  // useEffect(()=> {
  //   const toggleBtn1 =document.querySelector('.sidebar-toggle');
  //   const sidebar1 =document.querySelector('body');

  //   toggleBtn1.addEventListener('click', function(){
  //       sidebar1.classList.toggle('open-menu');
  //   });
  
  // },[])
  const handleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const sidebarStatus = localStorage.getItem('cancel-sidebar-close')

    const sidebar1 =document.querySelector('body');
    if(isSidebarOpen && !sidebarStatus){
      sidebar1.classList.remove('open-menu');
    }else{
      sidebar1.classList.add('open-menu');
      localStorage.removeItem('cancel-sidebar-close')
    }
  };
 
  return (
    <React.Fragment>
       <header className="das-header">
        <div className="container-header">
            <div className="header-inner d-flex align-items-center">
                <div className="logo">
                    <a href=""><img src="images/logo.png" alt="" /></a>
                </div>
                <div className="navbar-full d-flex align-items-center">
                    <button type="button" className="sidebar-toggle" onClick={handleSidebar}>
                          <div className="bar1"></div>
                          <div className="bar2"></div>
                          <div className="bar3"></div>
                    </button>
                    <div id="basic" data-input-name="country"></div>
                    <div className="header-right ms-auto">
                        <div className="top-area d-flex align-items-center">
                            <div className="bell">
                                <div className="dropdown px-2">
                                    <div className="noti-icon" id="page-header-notifications-dropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="mdi mdi-bell bx-tada"></i>
                                        <span className="badge bg-danger">3</span>
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                                        <div className="p-3">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h6 className="m-0" key="t-notifications"> Notifications </h6>
                                                </div>
                                                <div className="col-auto">
                                                    <a href="#!" className="small" key="t-view-all"> View All</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="javascript:void(0);" className="text-reset notification-item dropdown-item">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1" key="t-your-order">Your order is placed</h6>
                                                        <div className="font-size-12 text-muted">
                                                            <p className="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                                                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="text-reset notification-item dropdown-item">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">James Lemire</h6>
                                                        <div className="font-size-12 text-muted">
                                                            <p className="mb-1" key="t-simplified">It will seem like simplified English.</p>
                                                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="text-reset notification-item dropdown-item">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1" key="t-shipped">Your item is shipped</h6>
                                                        <div className="font-size-12 text-muted">
                                                            <p className="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                                                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="javascript:void(0);" className="text-reset notification-item dropdown-item">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Salena Layfield</h6>
                                                        <div className="font-size-12 text-muted">
                                                            <p className="mb-1" key="t-occidental">As a skeptical Cambridge friend of mine occidental.</p>
                                                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="p-2 border-top d-grid text-center">
                                            <a className="small" href="javascript:void(0)">
                                                <span>View More..</span> 
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ProfileMenu />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
