import {
  ADD_NEW_INDUSTRY,
  ADD_NEW_SKILL,
  DELETE_INDUSTRY,
  DELETE_SKILL,
  EDIT_INDUSTRY,
  EDIT_SKILL,
  INDUSTRY_LIST,
  SKILL_LIST,
} from "./actionTypes"

const {
  getSkill,
  addSkill,
  editSKill,
  deleteSkills,
  deleteIndustryData,
  editIndustryData,
  addIndustryData,
  getIndustryList,
} = require("helpers/backend_helper")
const { put, call, takeEvery } = require("redux-saga/effects")
const {
  getIndustrySucceess,
  getIndustryError,
  editIndustrySuccess,
  editIndustryError,
  addIndustrySuccess,
  addIndustryError,
} = require("./actions")

function* getIndustries() {
  try {
    const responce = yield call(getIndustryList)
    if (responce && responce.status) {
      yield put(getIndustrySucceess(responce.data))
    } else {
      yield put(getIndustryError(responce.message))
    }
  } catch (error) {
    yield put(getIndustryError(error))
  }
}

function* addIndustry({ payload: data }) {
  try {
    console.log(data)
    const responce = yield call(addIndustryData, data)
    if (responce && responce.status) {
      const resp = yield call(getIndustryList)
      if (resp && resp.status) {
        yield put(getIndustrySucceess(resp.data))
        yield put(addIndustrySuccess(responce.message))
      } else {
        yield put(addIndustryError(responce.message))
      }
    } else {
      yield put(getIndustryError(responce.message))
    }
  } catch (error) {
    yield put(addIndustryError(error))
  }
}

function* editIndustry({ payload: data }) {
  try {
    const responce = yield call(editIndustryData, data)
    if (responce && responce.status) {
      const resp = yield call(getIndustryList)
      if (resp && resp.status) {
        yield put(getIndustrySucceess(resp.data))
        yield put(editIndustrySuccess(responce.message))
      } else {
        yield put(getIndustryError(responce.message))
      }
    } else {
      yield put(editIndustryError(responce.message))
    }
  } catch (err) {
    yield put(editIndustryError(err))
  }
}

function* deleteIndustry({ payload: data }) {
  try {
    const responce = yield call(deleteIndustryData, data)
    console.log(data)
    if (responce && responce.status) {
      const resp = yield call(getIndustryList)
      if (resp && resp.status) {
        yield put(getIndustrySucceess(resp.data))
        yield put(deleteIndustrySuccess(responce.message))
      } else {
        yield put(getIndustryError(resp.message))
      }
    } else {
      yield put(deleteIndustryError(responce.message))
    }
  } catch (err) {
    yield put(deleteIndustryError(err))
  }
}

function* industrySaga() {
  yield takeEvery(INDUSTRY_LIST, getIndustries)
  yield takeEvery(ADD_NEW_INDUSTRY, addIndustry)
  yield takeEvery(EDIT_INDUSTRY, editIndustry)
  yield takeEvery(DELETE_INDUSTRY, deleteIndustry)
}

export default industrySaga
