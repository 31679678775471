const { MYADVISOR } = require("helpers/backend_url_helper")
const {
  REQUESTLISTSUCCESS,
  REQUESTLISTError,
  REQUEST_SENT_SUCCESS,
  REQUEST_SENT_FAIL
} = require("./actionTypes")

const initialState = {
  error: "",
  loading: false,
  request: [],
  request_sent: []
}

const MyRequestAdvisor = (state = initialState, action) => {
  switch (action.type) {
    case REQUESTLISTSUCCESS:
      state = {
        ...state,
        request: action.payload,
      }
    case REQUESTLISTError:
      state = {
        ...state,
        error: action.payload,
      }
    case REQUEST_SENT_SUCCESS:
      state = {
        ...state,
        request_sent: action.payload,
      }
    case REQUEST_SENT_FAIL:
      state = {
        ...state,
        error: action.payload,
      }
    
  }
  return state
}

export default MyRequestAdvisor
