import { profileError } from "store/actions"

const {
  userList,
  updateAdvisorStatus,
  getProfile,
  updateProfileData,
  deleteProfileData,
} = require("helpers/backend_helper")
const { call, put, takeEvery } = require("redux-saga/effects")
const {
  userListError,
  userListSuccess,
  userActionError,
  usersActionSucces,
  userProfileSuccess,
  userProfileError,
  profileUpdateSuccess,
  profileUpdateError,
  profileDeleteSuccess,
  profileDeleteError,
} = require("./actions")
const {
  USERS_LIST,
  USER_ACTION,
  PROFILE,
  UPDATEPROFILE,
  PROFLE_DELETE,
} = require("./actionTypes")

function* getUsersList() {
  try {
    const responce = yield call(userList)
    if (responce && responce.status) {
      yield put(userListSuccess(responce.data))
    } else {
      yield put(userListError(responce.message))
    }
  } catch (error) {
    yield put(userListError(error))
  }
}

function* postUserAction({ payload: data }) {
  try {
    const responce = yield call(updateAdvisorStatus, data)
    if (responce && responce.status) {
      yield put(usersActionSucces(responce.message))
    } else {
      yield put(userActionError(responce.message))
    }
  } catch (err) {
    yield put(userActionError(err))
  }
}

function* getProfileDetails({ payload: history  }) {
  try {
    const responce = yield call(getProfile)
    if (responce && responce.status) {
      yield put(userProfileSuccess(responce.data))
      if(responce.data.on_boarding === 0){
        history.push(`/register-info?email=${responce.data.email}&id=${responce.data.id}`)
      }
    } else {
      yield put(userProfileError(responce.message))
    }
  } catch (error) {
    yield put(userProfileError(error))
  }
}

function* updateProfile({ payload: data }) {
  try {
    const responce = yield call(updateProfileData, data)
    if (responce && responce.status) {
      yield put(profileUpdateSuccess(responce))
     // window.location.reload()
    } else {
      yield put(profileUpdateError(responce))
    }
  } catch (error) {
    yield put(profileUpdateError(error))
  }
}

function* deleteProfile() {
  try {
    const responce = yield call(deleteProfileData)
    if (responce && responce.status) {
      yield put(profileDeleteSuccess(responce))
    } else {
      yield put(profileDeleteError(responce))
    }
  } catch (error) {
    yield put(profileDeleteError(error))
  }
}

function* userSaga() {
  yield takeEvery(USERS_LIST, getUsersList)
  yield takeEvery(USER_ACTION, postUserAction)
  yield takeEvery(PROFILE, getProfileDetails)
  yield takeEvery(UPDATEPROFILE, updateProfile)
  yield takeEvery(PROFLE_DELETE,deleteProfile)
}

export default userSaga
