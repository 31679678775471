import {
  ADD_NEW_SKILL,
  DELETE_SKILL,
  EDIT_SKILL,
  SKILL_LIST,
} from "./actionTypes"

const {
  getSkill,
  addSkill,
  editSKill,
  deleteSkills,
} = require("helpers/backend_helper")
const { put, call, takeEvery } = require("redux-saga/effects")
const {
  getSkillSuccess,
  getSKIllError,
  addSkillError,
  addSkillSuccess,
  editSkillError,
  editSkillSuccess,
  deleteSkillError,
  deleteSkillSuccess,
} = require("./actions")

function* getSkills() {
  try {
    const responce = yield call(getSkill)
    if (responce && responce.status) {
      yield put(getSkillSuccess(responce.data))
    } else {
      yield put(getSKIllError(responce.message))
    }
  } catch (error) {
    yield put(getSKIllError(error))
  }
}

function* addSkills({ payload: { data, history } }) {
  try {
    const responce = yield call(addSkill, data)
    if (responce && responce.status) {
      const resp = yield call(getSkill)
      if (resp && resp.status) {
        yield put(getSkillSuccess(resp.data))
        yield put(addSkillSuccess(responce.message))
      } else {
        yield put(getSKIllError(responce.message))
      }
    } else {
      yield put(addSkillError(responce.message))
    }
  } catch (error) {
    yield put(addSkillError(error))
  }
}

function* editSkill({ payload: { data, history } }) {
  try {
    const responce = yield call(editSKill, data)
    if (responce && responce.status) {
      const resp = yield call(getSkill)
      if (resp && resp.status) {
        yield put(getSkillSuccess(resp.data))
        yield put(editSkillSuccess(responce.message))
      } else {
        yield put(getSKIllError(responce.message))
      }
    } else {
      yield put(editSkillError(responce.message))
    }
  } catch (err) {
    yield put(editSkillError(err))
  }
}

function* deleteSkill({ payload: data }) {
  try {
    const responce = yield call(deleteSkills, data)
    console.log(data)
    if (responce && responce.status) {
      const resp = yield call(getSkill)
      if (resp && resp.status) {
        yield put(getSkillSuccess(resp.data))
        yield put(deleteSkillSuccess(responce.message))
      } else {
        yield put(getSKIllError(resp.message))
      }
    } else {
      yield put(deleteSkillError(responce.message))
    }
  } catch (err) {
    yield put(deleteSkillError(err))
  }
}

function* skillSaga() {
  yield takeEvery(SKILL_LIST, getSkills)
  yield takeEvery(ADD_NEW_SKILL, addSkills)
  yield takeEvery(EDIT_SKILL, editSkill)
  yield takeEvery(DELETE_SKILL, deleteSkill)
}

export default skillSaga
