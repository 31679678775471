import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_ADMIN,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutAdminSuccess,
  logoutUserSuccess,
} from "./actions"

import {
  googleLogin,
  linkedInLogin,
  login,
  userLogin,
} from "helpers/backend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, {
      email: user.email,
      password: user.password,
    })
    if (response && response.status) {
      localStorage.setItem("authUser", JSON.stringify(response.data))
      localStorage.setItem("authtoken", response.data.api_token)
      yield put(loginSuccess(response))
      if(response.data.user.on_boarding === 1){
        history.push("/dashboard")
      }else{
        history.push(`/register-info?email=${response.data.user.email}&id=${response.data.user.id}`)
      }
    } else {
      yield put(apiError(response.message))
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutAdmin({ payload: { data, history } }) {
  try {
    const response = yield call(userLogin, {
      email: data.email,
    })
    if (response && response.status) {
      console.log(response)
      localStorage.removeItem("authUser")
      localStorage.removeItem("authToken")
      localStorage.setItem("authUser", JSON.stringify(response.data))
      localStorage.setItem("authtoken", response.data.api_token)
      yield put(loginSuccess(response))
      history.push("/dashboard")
    } else {
      yield put(apiError(response.message))
    }
    // yield put(logoutAdminSuccess("Admin Logout Successfully"))
  } catch (error) {
    yield put(apiError(error))
  }
}
function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (type == "google") {
      const response = yield call(googleLogin, data)
      if (response && response.status) {
        localStorage.setItem("authUser", JSON.stringify(response.data))
        localStorage.setItem("authtoken", response.data.api_token)
        yield put(loginSuccess(response))
        history.push("/dashboard")
      } else {
        yield put(apiError(response.message))
      }
    } else if (type == "linkedin") {
      const response = yield call(linkedInLogin, data)
      if (response && response.status) {
        localStorage.setItem("authUser", JSON.stringify(response.data))
        localStorage.setItem("authtoken", response.data.api_token)
        yield put(loginSuccess(response))
        history.push("/dashboard")
      } else {
        yield put(apiError(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(LOGOUT_ADMIN, logoutAdmin)
}

export default authSaga
