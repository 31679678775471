import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER, REGISTER_USER_INFO } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed, registerUserInfoSuccessful, registerUserInfoFailed } from "./actions"

import { login, register, registerInfo } from "helpers/backend_helper"

function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(register, user)
    const responseData = response.data 
    yield put(registerUserSuccessful(response))
    history.push(`/register-info?email=${user.email}&id=${responseData.user.id}`) // Use response data in history push
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

function* registerInfoUser({ payload: { user, history } }) {
  try {
    const response = yield call(registerInfo, user)
    yield put(registerUserInfoSuccessful(response))
    history.push("/")
  } catch (error) {
    yield put(registerUserInfoFailed(error))
  }
}


export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

export function* watchUserInfoRegister() {
  yield takeEvery(REGISTER_USER_INFO, registerInfoUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister), fork(watchUserInfoRegister)])
}

export default accountSaga
