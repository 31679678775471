import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import {
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap"

import { Link } from "react-router-dom";

// import logo from "../../assets/images/logo.svg";
// import logoLightPng from "../../assets/images/logo-light.png";
// import logoLightSvg from "../../assets/images/logo-light.svg";
// import logoDark from "../../assets/images/logo-dark.png";
import {becomeAdvisor} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"


const Sidebar = props => {
    const dispatch = useDispatch()
    const [role, setRole] = useState("")
    const [isAdvisory, setIsAdvisory] = useState(0)
    const [becomeAdvisorStatus, setBecomeAdvisorStatus] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(true);

    const { becomeAdvisorData, details } = useSelector(state => ({
        becomeAdvisorData: state.MyAdvisor.become_advisor,
        details: state.Users.details,
      }))
    

    useEffect(() => {
        // ref.current.recalculate()
        //const obj = JSON.parse(localStorage.getItem("authUser"))
        // setRole(obj.user.role_id)
        // setIsAdvisory(obj.user.is_advisory)
        setRole(details.role_id)
        setIsAdvisory(details.is_advisory)
    },[details])

    useEffect(()=> {
        setBecomeAdvisorStatus(becomeAdvisorData)
    },[becomeAdvisorData])

    const handleSidebarClose = () => {
        const sidebar1 = document.querySelector('body');
        sidebar1.classList.remove('open-menu');
        localStorage.setItem('cancel-sidebar-close', true)
    }

    function setActiveMenuItem() {
        const currentUrl = window.location.pathname;
        const menuItems = document.querySelectorAll('.memu-item');
        menuItems.forEach(item => {
          const link = item.querySelector('a');
          if (link.getAttribute('href') === currentUrl) {
            item.classList.add('item-selected');
          } else {
            item.classList.remove('item-selected');
          }
        });
        const sidebar1 = document.querySelector('body');
        sidebar1.classList.remove('open-menu');
    }
      
      setActiveMenuItem();
      window.addEventListener('popstate', setActiveMenuItem);

    {/* delete profile picture */}
    const handleBecomeAdvisor = () => {
        dispatch(becomeAdvisor())
    }  
   
  return (
    <React.Fragment>
      {/* <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="19" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div> */}
     
      <div className="app-sidebar sidebar-left" >
            <div className="closeIcn" onClick={handleSidebarClose}>
                <span><i className="mdi mdi-close"></i></span>
            </div>

            <div className="sidebar-inner">
                <div className="sidebar-list-item">
                    <ul className="list-unstyled">
                        <li className="side-nav-title">
                            <span>MENU</span>
                        </li>
                        <li className="side-nav-item memu-item" >
                            <Link to="/dashboard"><span className="nav-item-img"><svg id="bx-home-circle"
                                        xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <rect width="20" height="20" fill="none" />
                                        <path
                                            d="M13.333,16.667h-10A1.669,1.669,0,0,1,1.667,15V9.167H.833a.833.833,0,0,1-.59-1.422l7.5-7.5a.831.831,0,0,1,1.178,0l7.5,7.5a.833.833,0,0,1-.589,1.422H15V15A1.669,1.669,0,0,1,13.333,16.667Zm-5-14.656h0l-5,5V15h10V7.012l-5-5Z"
                                            transform="translate(1.667 1.666)" fill="#6A7187" />
                                        <path data-name="Shape"
                                            d="M3.334,6.666A3.333,3.333,0,1,1,6.666,3.334,3.337,3.337,0,0,1,3.334,6.666Zm0-5A1.667,1.667,0,1,0,5,3.334,1.669,1.669,0,0,0,3.334,1.667Z"
                                            transform="translate(6.667 7.5)" fill="#6A7187" />
                                    </svg>
                                </span> Dashboard</Link>
                        </li>
                        <li className="side-nav-title">
                            <span>Advisors</span>
                        </li>
                        <li className="side-nav-item memu-item">
                            <Link to="/search-advisor"><span className="nav-item-img"><svg id="bx-search-alt"
                                        xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <rect width="20" height="20" fill="none" />
                                        <path
                                            d="M15.227,16.472h0L11.355,12.6a6.967,6.967,0,0,1-4.311,1.487,7.044,7.044,0,1,1,7.044-7.044A6.972,6.972,0,0,1,12.6,11.356l3.87,3.87-1.244,1.245ZM7.044,1.76a5.283,5.283,0,1,0,5.282,5.284A5.29,5.29,0,0,0,7.044,1.76Z"
                                            transform="translate(1.333 1.333)" fill="#74788d" />
                                        <path data-name="Shape"
                                            d="M2.825,1.7a1.327,1.327,0,0,1,.392.943H4.551A2.651,2.651,0,0,0,3.768.756,2.726,2.726,0,0,0,0,.756L.941,1.7a1.363,1.363,0,0,1,1.884,0Z"
                                            transform="translate(6.454 5.231)" fill="#74788d" />
                                    </svg>
                                </span> Search</Link>
                        </li>
                        <li className="side-nav-item memu-item">
                            <Link to="/my-advisors"><span className="nav-item-img"><svg xmlns="http://www.w3.org/2000/svg" width="20"
                                        height="20" viewBox="0 0 20 20">
                                        <rect width="20" height="20" fill="none" />
                                        <path
                                            d="M8.333,5H6.666V3.334H8.333V5ZM5,5H3.334V3.334H5V5ZM1.667,5H0V3.334H1.667V5ZM8.333,1.667H6.666V0H8.333V1.666ZM5,1.667H3.334V0H5V1.666Zm-3.334,0H0V0H1.667V1.666Z"
                                            transform="translate(5.833 9.167)" fill="#6a7187" />
                                        <path data-name="Shape"
                                            d="M13.333,16.667H1.667A1.669,1.669,0,0,1,0,15V3.334A1.669,1.669,0,0,1,1.667,1.667H3.334V0H5V1.667h5V0h1.667V1.667h1.667A1.669,1.669,0,0,1,15,3.334V15A1.669,1.669,0,0,1,13.333,16.667ZM1.667,5V15H13.334V5Z"
                                            transform="translate(2.5 1.667)" fill="#6a7187" />
                                    </svg>
                                </span> My Advisors</Link>
                        </li>
                        <li className="side-nav-item memu-item">
                            <Link to="/saved-advisor"><span className="nav-item-img">
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="20" height="20" viewBox="0 0 20 20">
                                        <defs>
                                            <clipPath>
                                                <rect width="20" height="20" fill="none" />
                                            </clipPath>
                                        </defs>
                                        <g data-name="Group 1" transform="translate(-24 -341.5)">
                                            <g transform="translate(24 341.5)">
                                                <rect data-name="Background" width="20" height="20" fill="none" />
                                                <g id="bx-file-2" data-name="bx-file" 
                                                // clip-path="url(#clip-path)"
                                                >
                                                    <path
                                                        d="M11.667,16.667h-10A1.669,1.669,0,0,1,0,15V1.667A1.669,1.669,0,0,1,1.667,0H7.5a.1.1,0,0,1,.028.005.123.123,0,0,0,.023.005.846.846,0,0,1,.216.042L7.789.06a.486.486,0,0,1,.056.021.837.837,0,0,1,.244.163l5,5a.824.824,0,0,1,.163.244.5.5,0,0,1,.021.056l.007.021a.846.846,0,0,1,.042.216.129.129,0,0,0,.005.024.1.1,0,0,1,.005.028V15A1.669,1.669,0,0,1,11.667,16.667Zm-10-15V15h10l0-8.334H7.5a.834.834,0,0,1-.833-.833V1.667ZM8.333,2.845V5h2.155Z"
                                                        transform="translate(3.333 1.667)" fill="#6a7187" />
                                                    <path data-name="Shape"
                                                        d="M6.666,8.333H0V6.666H6.666V8.332ZM6.666,5H0V3.334H6.666V5Zm-5-3.334H0V0H1.667V1.666Z"
                                                        transform="translate(6.667 6.667)" fill="#6a7187" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                </span> Saved Advisors</Link>
                        </li>
                        { (isAdvisory !== 1 && role !== 1)  &&
                        <li className="side-nav-item " onClick={handleBecomeAdvisor}>
                            <Link to="#">
                                <span className="nav-item-img"><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                        height="16" viewBox="0 0 16 16">
                                        <path data-name="Path 194"
                                            d="M1.6,14.4a1.541,1.541,0,0,1-1.13-.47A1.538,1.538,0,0,1,0,12.8V1.6A1.541,1.541,0,0,1,.47.47,1.538,1.538,0,0,1,1.6,0H12.8a1.541,1.541,0,0,1,1.13.47A1.538,1.538,0,0,1,14.4,1.6V6.96a6.6,6.6,0,0,0-.78-.31,4.8,4.8,0,0,0-.82-.19V1.6H1.6V12.8H6.44a4.794,4.794,0,0,0,.19.84,5.594,5.594,0,0,0,.31.76Zm0-2.4v0Zm1.6-.8H6.46a4.856,4.856,0,0,1,.19-.82q.13-.4.29-.78H3.2ZM3.2,8H8.08A6.12,6.12,0,0,1,9.51,7a5.638,5.638,0,0,1,1.69-.54V6.4h-8Zm0-3.2h8V3.2h-8ZM12,16a3.995,3.995,0,0,1-4-4,3.995,3.995,0,0,1,4-4,3.995,3.995,0,0,1,4,4,3.995,3.995,0,0,1-4,4Zm-.4-1.6h.8v-2h2v-.8h-2v-2h-.8v2h-2v.8h2Z"
                                            fill="#6a7187" />
                                    </svg>
                                </span> Become An Advisor</Link>
                        </li>
                        }
                        <li className="side-nav-title">
                            <span>Profile</span>
                        </li>
                        <li className="side-nav-item memu-item">
                            <Link to="/profile"><span className="nav-item-img">
                                    <svg id="bx-user-circle" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                        viewBox="0 0 20 20">
                                        <rect id="Background" width="20" height="20" fill="none" />
                                        <path 
                                            d="M1.667,0A1.594,1.594,0,0,0,0,1.667,1.594,1.594,0,0,0,1.667,3.333,1.594,1.594,0,0,0,3.333,1.667,1.594,1.594,0,0,0,1.667,0Z"
                                            transform="translate(8.333 6.667)" fill="none" />
                                        <path data-name="Shape"
                                            d="M1.991,11.369h0A6.613,6.613,0,0,1,0,6.666,6.756,6.756,0,0,1,6.666,0a6.756,6.756,0,0,1,6.667,6.666,6.607,6.607,0,0,1-1.991,4.7A4.164,4.164,0,0,0,7.5,8.811H5.833a4.161,4.161,0,0,0-3.841,2.557Zm4.675-9.7A3.26,3.26,0,0,0,3.334,5,3.26,3.26,0,0,0,6.666,8.333,3.26,3.26,0,0,0,10,5,3.26,3.26,0,0,0,6.666,1.667Z"
                                            transform="translate(3.333 3.333)" fill="none" />
                                        <path id="Shape-3" data-name="Shape"
                                            d="M4.105,0H2.438A2.5,2.5,0,0,0,0,1.962a6.508,6.508,0,0,0,3.272.895,6.516,6.516,0,0,0,3.272-.894A2.5,2.5,0,0,0,4.105,0Z"
                                            transform="translate(6.728 13.81)" fill="none" />
                                        <path id="Shape-4" data-name="Shape"
                                            d="M8.333,16.667A8.184,8.184,0,0,1,3.418,15H3.334v-.064A8.41,8.41,0,0,1,0,8.333,8.445,8.445,0,0,1,8.333,0a8.445,8.445,0,0,1,8.334,8.333,8.412,8.412,0,0,1-3.334,6.6V15h-.086A8.18,8.18,0,0,1,8.333,16.667ZM7.5,12.145a2.514,2.514,0,0,0-2.438,1.961,6.429,6.429,0,0,0,6.543,0,2.514,2.514,0,0,0-2.438-1.961Zm0-1.667H9.167a4.162,4.162,0,0,1,3.842,2.556A6.606,6.606,0,0,0,15,8.333,6.756,6.756,0,0,0,8.333,1.667,6.756,6.756,0,0,0,1.667,8.333a6.6,6.6,0,0,0,1.992,4.7A4.161,4.161,0,0,1,7.5,10.478Z"
                                            transform="translate(1.667 1.667)" fill="#6a7187" />
                                        <path id="Shape-5" data-name="Shape"
                                            d="M3.334,6.666A3.26,3.26,0,0,1,0,3.334,3.26,3.26,0,0,1,3.334,0,3.26,3.26,0,0,1,6.666,3.334,3.26,3.26,0,0,1,3.334,6.666Zm0-5A1.6,1.6,0,0,0,1.667,3.334,1.6,1.6,0,0,0,3.334,5,1.6,1.6,0,0,0,5,3.334,1.6,1.6,0,0,0,3.334,1.667Z"
                                            transform="translate(6.667 5)" fill="#6a7187" />
                                    </svg>

                                </span> My profile</Link>
                        </li>
                      
                        {
                            role == 1 ? (
                                <>
                                    <li className="side-nav-title">
                                        <span>Admin</span>
                                    </li>
                                    <li className="side-nav-item memu-item">
                                        <Link to="/users-list"><span className="nav-item-img">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
                                                    <path
                                                        d="M16.845,0H6.5V20h15V4.862Zm-4.72,13.927-1.6,1.333a.31.31,0,0,1-.425-.025l-.638-.667a.344.344,0,0,1,0-.471.309.309,0,0,1,.451,0l.436.456,1.373-1.147a.31.31,0,0,1,.448.052A.343.343,0,0,1,12.125,13.927Zm0-3.667-1.6,1.333a.31.31,0,0,1-.425-.025L9.466,10.9a.344.344,0,0,1,0-.471.309.309,0,0,1,.451,0l.436.456L11.726,9.74a.31.31,0,0,1,.448.052A.343.343,0,0,1,12.125,10.26Zm0-3.667-1.6,1.333A.31.31,0,0,1,10.1,7.9l-.638-.667a.344.344,0,0,1,0-.471.309.309,0,0,1,.451,0l.436.456,1.373-1.147a.31.31,0,0,1,.448.052A.343.343,0,0,1,12.125,6.594ZM17.989,15H12.883a.334.334,0,0,1,0-.667h5.106a.334.334,0,0,1,0,.667Zm0-3.667H12.883a.334.334,0,0,1,0-.667h5.106a.334.334,0,0,1,0,.667Zm0-3.667H12.883a.334.334,0,0,1,0-.667h5.106a.334.334,0,0,1,0,.667Zm-1.6-2.333V.667l4.468,4.667Z"
                                                        transform="translate(-6.5)" fill="#6a7187" />
                                                </svg>

                                            </span> Users List</Link>
                                    </li>
                                    <li className="side-nav-item memu-item">
                                        <Link to="/skills-list"><span className="nav-item-img">
                                                <svg data-name="Group 11" xmlns="http://www.w3.org/2000/svg"
                                                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    width="19.303" height="20"
                                                    viewBox="0 0 19.303 20">
                                                    <defs>
                                                        <clipPath id="clip-path">
                                                            <rect data-name="Rectangle 2" width="19.303" height="20"
                                                                fill="#6a7187" />
                                                        </clipPath>
                                                    </defs>
                                                    <g data-name="Group 10" 
                                                    //  clip-path="url(#clip-path)"
                                                    >
                                                        <path data-name="Path 18"
                                                            d="M205.185,180.693a11.4,11.4,0,0,0-.627-1.592c-.328-.693-.667-1.381-.983-2.079a6.175,6.175,0,0,1-.427-1.44,7.432,7.432,0,0,1-.128-1.621,4.648,4.648,0,0,1,.471-1.88,4.709,4.709,0,0,1,1.706-1.92,4.487,4.487,0,0,1,1.492-.624,4.819,4.819,0,0,1,1.95-.05,4.7,4.7,0,0,1,2.611,1.436,4.635,4.635,0,0,1,1.017,1.715,5.069,5.069,0,0,1,.2,2.3,12.5,12.5,0,0,1-.229,1.253,7.236,7.236,0,0,1-.553,1.459c-.334.7-.677,1.392-1,2.1-.139.3-.235.629-.345.929a1.946,1.946,0,0,1,.243.184.318.318,0,0,1,.047.381.345.345,0,0,1-.332.2q-1.5,0-3.008-.005h-2.054a.347.347,0,0,1-.344-.219.328.328,0,0,1,.073-.38,1.379,1.379,0,0,1,.223-.141m2.573.027v0c.536,0,1.071,0,1.607,0,.124,0,.16-.048.194-.152.13-.391.245-.79.412-1.166.31-.7.647-1.381.982-2.066a7.059,7.059,0,0,0,.765-3.179,3.975,3.975,0,0,0-.549-2.02,3.778,3.778,0,0,0-1.37-1.378,4.009,4.009,0,0,0-2.464-.545,3.875,3.875,0,0,0-1.683.576,3.917,3.917,0,0,0-1.455,1.6,3.576,3.576,0,0,0-.389,1.383,6.749,6.749,0,0,0,.055,1.408,6.6,6.6,0,0,0,.5,1.74c.223.5.471.994.715,1.487a12,12,0,0,1,.877,2.2.133.133,0,0,0,.154.111c.548,0,1.1,0,1.643,0"
                                                            transform="translate(-198.109 -165.316)" fill="#6a7187" />
                                                        <path data-name="Path 19"
                                                            d="M280.265,773.757c-.135,0-.263,0-.391,0a.358.358,0,1,1,.005-.715c1.675,0,3.35,0,5.025-.006a.39.39,0,0,1,.392.369.366.366,0,0,1-.392.354h-.392c0,.033,0,.052,0,.071a.265.265,0,0,1-.278.328c-.62,0-1.24,0-1.86,0l-1.558-.008h-.157c-.322,0-.377-.052-.395-.392"
                                                            transform="translate(-272.737 -754.353)" fill="#6a7187" />
                                                        <path data-name="Path 20"
                                                            d="M282.127,702.681c-.834,0-1.667,0-2.5,0a.549.549,0,0,1-.3-.113.306.306,0,0,1-.076-.375.343.343,0,0,1,.343-.22H280.8l3.818,0a.361.361,0,1,1,.061.718c-.85,0-1.7,0-2.549,0h0"
                                                            transform="translate(-272.467 -684.997)" fill="#6a7187" />
                                                        <path data-name="Path 21"
                                                            d="M376.858,1.353c0,.3.021.6-.006.9a.539.539,0,0,1-.553.455.566.566,0,0,1-.569-.542c-.007-.527,0-1.054,0-1.581a.593.593,0,0,1,.6-.586.586.586,0,0,1,.548.58c.006.257,0,.515,0,.772h-.021"
                                                            transform="translate(-366.645 -0.001)" fill="#6a7187" />
                                                        <path data-name="Path 22"
                                                            d="M688.055,365.606c-.266,0-.531,0-.8,0a.56.56,0,0,1-.552-.543.546.546,0,0,1,.489-.585c.4-.021.8-.013,1.2-.016a4.668,4.668,0,0,1,.494.009.569.569,0,0,1,.516.6.582.582,0,0,1-.581.533c-.257-.009-.515,0-.773,0Z"
                                                            transform="translate(-670.108 -355.652)" fill="#6a7187" />
                                                        <path data-name="Path 23"
                                                            d="M1.353,365.766c-.258,0-.516-.006-.773,0A.574.574,0,0,1,0,365.235a.56.56,0,0,1,.521-.6c.136-.013.273-.007.41-.008.395,0,.789,0,1.184,0a.551.551,0,0,1,.529.334.531.531,0,0,1-.071.589.527.527,0,0,1-.424.216c-.266,0-.532,0-.8,0h0"
                                                            transform="translate(0 -355.814)" fill="#6a7187" />
                                                        <path data-name="Path 24"
                                                            d="M588.13,587.773a.578.578,0,0,1,.439.227q.436.44.876.876a.562.562,0,0,1,.14.649.5.5,0,0,1-.563.3.723.723,0,0,1-.341-.181c-.323-.306-.632-.627-.945-.942a.566.566,0,0,1-.125-.613.54.54,0,0,1,.521-.313"
                                                            transform="translate(-573.367 -573.566)" fill="#6a7187" />
                                                        <path data-name="Path 25"
                                                            d="M587.62,126.866a.473.473,0,0,1,.145-.359q.511-.517,1.032-1.025a.519.519,0,0,1,.71,0,.513.513,0,0,1,.058.735c-.323.339-.664.661-.99,1a.543.543,0,0,1-.552.178.529.529,0,0,1-.4-.53"
                                                            transform="translate(-573.417 -122.314)" fill="#6a7187" />
                                                        <path data-name="Path 26"
                                                            d="M127.512,126.944a.536.536,0,0,1-.317.542.51.51,0,0,1-.583-.094c-.348-.337-.688-.683-1.025-1.031a.532.532,0,0,1,.736-.766c.245.224.476.465.71.7a4.722,4.722,0,0,1,.384.412.766.766,0,0,1,.095.236"
                                                            transform="translate(-122.415 -122.435)" fill="#6a7187" />
                                                        <path data-name="Path 27"
                                                            d="M126.825,587.8a.529.529,0,0,1,.408.895q-.506.517-1.025,1.02a.531.531,0,0,1-.767-.732c.34-.357.691-.7,1.043-1.05a.427.427,0,0,1,.34-.134"
                                                            transform="translate(-122.284 -573.591)" fill="#6a7187" />
                                                        <path data-name="Path 28" d="M379.955,825.871h.713v.039h-.714v-.039"
                                                            transform="translate(-370.772 -805.91)" fill="#6a7187" />
                                                        <path data-name="Path 29"
                                                            d="M338.155,344.4a.508.508,0,0,1-.33.385.488.488,0,0,1-.662-.482c-.011-.829,0-1.659,0-2.488,0-.09-.015-.133-.118-.163a.467.467,0,0,1-.376-.514c.008-.833.013-1.667,0-2.5a.493.493,0,0,1,.539-.532,3.941,3.941,0,0,0,.507,0,.162.162,0,0,1,.179.105,3.44,3.44,0,0,0,.174.317c.06.1.1.105.167,0s.119-.229.191-.335a.2.2,0,0,1,.14-.082c.229-.008.459-.006.688,0a.453.453,0,0,1,.44.441q0,1.323,0,2.646a.448.448,0,0,1-.376.464c-.112.023-.117.076-.116.163q0,1.244,0,2.489a.486.486,0,0,1-.291.464.461.461,0,0,1-.53-.072,1.087,1.087,0,0,1-.153-.225.514.514,0,0,1-.034-.079l-.033,0"
                                                            transform="translate(-328.527 -329.927)" fill="#6a7187" />
                                                        <path data-name="Path 30"
                                                            d="M356.068,241.879a1.066,1.066,0,1,1-1.082,1.074,1.037,1.037,0,0,1,1.082-1.074"
                                                            transform="translate(-346.406 -236.03)" fill="#6a7187" />
                                                    </g>
                                                </svg>

                                            </span> Skills</Link>
                                    </li>
                                    <li className="side-nav-item memu-item">
                                        <Link to="/industry-list"><span className="nav-item-img">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21.116"
                                                    viewBox="0 0 20 21.116">
                                                    <path
                                                        d="M98.613,72.733a2.643,2.643,0,1,0,1.867.774A2.619,2.619,0,0,0,98.613,72.733Zm9.73,5.558L106.8,76.971a8.416,8.416,0,0,0,0-2.723l1.544-1.319a.756.756,0,0,0,.219-.831l-.021-.061a10.443,10.443,0,0,0-1.879-3.25l-.042-.049a.758.758,0,0,0-.828-.224l-1.916.682a8.239,8.239,0,0,0-2.351-1.357l-.37-2a.757.757,0,0,0-.609-.607l-.064-.012a10.634,10.634,0,0,0-3.748,0l-.064.012a.756.756,0,0,0-.609.607l-.373,2.013A8.34,8.34,0,0,0,93.355,69.2l-1.93-.687a.755.755,0,0,0-.828.224l-.042.049a10.525,10.525,0,0,0-1.878,3.25l-.021.061a.758.758,0,0,0,.219.831l1.562,1.333a8.294,8.294,0,0,0-.109,1.345,8.209,8.209,0,0,0,.109,1.345l-1.558,1.333a.756.756,0,0,0-.219.831l.021.061a10.471,10.471,0,0,0,1.879,3.25l.042.05a.758.758,0,0,0,.828.224l1.93-.687a8.175,8.175,0,0,0,2.334,1.352l.373,2.013a.756.756,0,0,0,.609.607l.064.012a10.58,10.58,0,0,0,3.748,0l.064-.012a.756.756,0,0,0,.609-.607l.371-2a8.3,8.3,0,0,0,2.351-1.357l1.916.682a.755.755,0,0,0,.828-.224l.042-.05a10.524,10.524,0,0,0,1.879-3.25l.021-.061A.762.762,0,0,0,108.343,78.291Zm-9.73,1.232a4.149,4.149,0,1,1,4.149-4.149A4.149,4.149,0,0,1,98.613,79.523Z"
                                                        transform="translate(-88.609 -65.05)" fill="#6a7187" />
                                                </svg>

                                            </span> Industry</Link>
                                    </li>
                                </>
                            ) : null
                        }
                        
                    </ul>
                </div>
            </div>
      </div>

      {becomeAdvisorStatus?.status ? 
                    <Modal isOpen={isModalOpen} toggle={()=>setIsModalOpen(false)} centered>
                      <ModalBody>
                        <div className="content-area-modal text-center">
                                  <img src="images/icon-success.svg" alt="" className="img" />
                                  <h2>Success</h2>
                                  <p> {becomeAdvisorStatus?.message} </p>
                                  <button type="button" className="btn btn-primary btn-green" onClick={()=>{
                                    setIsModalOpen(false)
                                    window.location.reload()
                                  }}>OK</button>
                                </div>
                      </ModalBody>
                    </Modal>
        : null}
     
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
