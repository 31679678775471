import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import React, { useEffect, useRef } from "react"

import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { logoutAdmin, logoutUser } from "../../store/actions"

//redux
// import { useSelector, useDispatch } from "react-redux"

const LoginAsAdmin = props => {
  const dispatch = useDispatch()
  document.title = "Login As User | Advisory Admin Dashboard"

  if (props.location && props.location.state) {
    var email = props.location.state.email
  } else {
    return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
  }

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  useEffect(() => {
    dispatch(logoutAdmin({ email: email }, props.history))
  }, [dispatch])

  return <></>
}

LoginAsAdmin.propTypes = {
  history: PropTypes.object,
}

export default withRouter(LoginAsAdmin)
