const {
  USERS_LIST,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  USER_ACTION_SUCCESS,
  USER_ACTION_ERROR,
  PROFILE,
  PROFILESUCCESS,
  PROFILEERROR,
  UPDATEPROFILESUCCESS,
  UPDATEPROFILEERROR,
  PROFILE_DELETE_SUCCESS,
  PROFILE_DELETE_ERROR
} = require("./actionTypes")

const INIT_STATE = {
  error: "",
  list: [],
  success: "",
  details: {},
  delete_profile:null,
  update_profile:null
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USERS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
      }
    case USERS_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case USER_ACTION_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case USER_ACTION_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case PROFILE:
      return {
        ...state,
        success: "",
        error: "",
        details: [],
      }
    case PROFILESUCCESS:
      return {
        ...state,
        success: "",
        error: "",
        details: action.payload,
        status: null,
      }
    case PROFILEERROR:
      return {
        ...state,
        error: action.payload,
        status: null,
      }
    case UPDATEPROFILESUCCESS:
      return {
        ...state,
        update_profile: action.payload,
        status: true,
      }
    case UPDATEPROFILEERROR:
      return {
        ...state,
        error: action.payload,
        status: false,
      }
      case PROFILE_DELETE_SUCCESS:
        return {
          ...state,
          delete_profile: action.payload,
          status: true,
        }
      case PROFILE_DELETE_ERROR:
        return {
          ...state,
          error: action.payload,
          status: false,
        }
    default:
      return state
  }
}

export default Users
