import React, { useEffect } from "react"

import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { requestAction, requestList } from "store/actions"
import { Link } from "react-router-dom"

const RequestList = () => {
  const dispatch = useDispatch()

  const { requestData } = useSelector(
    state => (
      console.log(state),
      {
        requestData: state.MyAdvisor.request,
      }
    )
  )
  useEffect(() => {
    dispatch(requestList())
  }, [])

  const handleAction = (id, status) => {
    const data = {
      request_id: id,
      status: status,
    }
    dispatch(requestAction(data))
  }

  //meta title
  document.title = "Join Request | Advisor Admin Dashboard"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Join Request" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>ID</Th>
                            {/* <Th>Profile</Th> */}
                            <Th>First Name</Th>
                            <Th>Last Name</Th>
                            <Th>Email</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {requestData && requestData.length > 0
                            ? requestData.map(
                                (req, index) => (
                                  console.log(req),
                                  (
                                    <Tr key={index}>
                                      <Td>{req.id}</Td>
                                      <Td>
                                        {req.requested_by_user.first_name}
                                      </Td>
                                      <Td>{req.requested_by_user.last_name}</Td>
                                      <Td>{req.requested_by_user.email}</Td>
                                      {req.status == 0 ? (
                                        <Td>
                                          <Link
                                            to="#"
                                            className="btn btn-info"
                                            onClick={() =>
                                              handleAction(req.id, 1)
                                            }
                                          >
                                            Accept
                                          </Link>{" "}
                                          <Link
                                            to="#"
                                            className="btn btn-danger"
                                            onClick={() =>
                                              handleAction(req.id, 2)
                                            }
                                          >
                                            Decline
                                          </Link>
                                        </Td>
                                      ) : req.status == 1 ? (
                                        <Td>
                                          <p className="text text-success">
                                            Approved
                                          </p>
                                        </Td>
                                      ) : (
                                        <Td>
                                          <p className="text text-danger">
                                            Declined
                                          </p>
                                        </Td>
                                      )}
                                    </Tr>
                                  )
                                )
                              )
                            : null}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RequestList
