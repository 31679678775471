import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// Formik Validation
import * as Yup from "yup"
import { setIn, useFormik } from "formik"
import { ErrorMessage, Field, Formik, FormikProvider } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import { getSkills, editSkill, addSkill, getIndustry, requestList, requestAction, profileDelete } from "store/actions"

import avatarImg from "../../assets/images/users/avatar-1.jpg"

// base url
import { API_URL } from "../../helpers/api_helper" 
// actions
import {
  editProfile,
  resetProfileFlag,
  userProfile,
  profileUpdate,
} from "../../store/actions"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const UserProfile = (props) => {
  //meta title
  document.title = "Profile | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)
  const [biography, setBiography] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(true);

  const inputHandler = (event, editor) => {
    setBiography(editor.getData())
  }

  const { error, success, status, requestData } = useSelector(state => ({
    error: state.Users.error,
    success: state.Users.success,
    status: state.Users.status,
    // requestData: state.MyAdvisor.request,
    requestData: state.MyRequestAdvisor.request,
  }))

  useEffect(() => {
    dispatch(userProfile(props.history))
    dispatch(getSkills())
    dispatch(getIndustry())
    dispatch(requestList())
  }, [])

  const [skilllist, setSelectedSkills] = useState([])
  const [industrylist, setSelectedIndustry] = useState([])

  const [skilldata, setSkills] = useState([])
  const [industrydata, setIndustry] = useState([])
  const [isAdvisory, setIsAdvisory] = useState(0)

  const [avatar, setAvatar] = useState(null)
  const [avatarURL, setAvatarURL] = useState(null)

  const [deleteProfileStatus, setDeleteProfileStatus] = useState(null)
  const [updateProfileStatus, setUpdateProfileStatus] = useState(null)
  const [isModalOpen2, setIsModalOpen2] = useState(true);
  const [isModalOpen3, setIsModalOpen3] = useState(true);

  const { skill } = useSelector(state => ({
    skill: state.Skills.data,
  }))

  const { industry } = useSelector(state => ({
    industry: state.Industry.data,
  }))

  const { details, deleteProfileData, updateProfileData } = useSelector(state => ({
    details: state.Users.details,
    deleteProfileData: state.Users.delete_profile,
    updateProfileData: state.Users.update_profile
  }))

  useEffect(() => {
    // ref.current.recalculate()
    const obj = JSON.parse(localStorage.getItem("authUser"))
    setIsAdvisory(obj.user.is_advisory)
  },[])

  useEffect(() => {
    if (details && details.skills && details.skills.length > 0) {
      var d = []
      var d1 = []
      details.skills.map((x, index) => {
        const data = { value: x.id, label: x.name }
        d.push(data)
      })
      details.industries.map((x, index) => {
        const data = { value: x.id, label: x.name }
        d1.push(data)
      })
      setSelectedSkills(d)
      setSelectedIndustry(d1)
    }
  }, [details])

  var optins = []
  var indoptins = []

  if (skill && skill.length > 0) {
    for (let sk of skill) {
      const data = { value: sk.id, label: sk.name }
      optins.push(data)
    }
  }

  if (industry && industry.length > 0) {
    for (let sk of industry) {
      const data = { value: sk.id, label: sk.name }
      indoptins.push(data)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  function handleSkills(selectedMulti) {
    setSelectedSkills(selectedMulti)
    setSkillData()
  }

  function setSkillData() {
    var d = []
    for (let i = 0; i < skilllist.length; i++) {
      console.log(skilllist[i])
      d.push(skilllist[i].label)
    }
    setSkills(d)
  }

  const handleImageChange =(event)=> {
    const file = event.target.files[0];
    const avatarURL = URL.createObjectURL(file)
    setAvatarURL(avatarURL)
    setAvatar(file)
  }

  function handleIndustry(selectedMulti) {
    setSelectedIndustry(selectedMulti)
    var d = []
    for (let i = 0; i < selectedMulti.length; i++) {
      d.push(selectedMulti[i].label)
    }
    setIndustry(d)
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: details ? details.first_name : "",
      last_name: details ? details.last_name : "",
      email: details ? details.email : "",
      skills: skilldata,
      industry: industrydata,
      linkedin_url: details ? details.linkedin_url : "",
      twitter_url: details ? details.twitter_url : "",
      current_title: details ? details.current_title : "",
      current_company: details ? details.current_company : "",
      biography: details ? details.biography : biography,
      profile_picture: details ? details.profile_picture : "",
      //avatar: avatar
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      //last_name: Yup.string().required("Please Enter Last Name"),
      email: Yup.string().required("Please Enter Your email"),
    }),
    onSubmit: values => {
      // console.log(values)
      //values.biography = biography
      const formData = new FormData();
    
      formData.append('avatar', avatar);
      formData.append('first_name', values.first_name);
      formData.append('last_name', values.last_name);
      formData.append('email', values.email);
      formData.append('skills', values.skills);
      formData.append('industry', values.industry);
      formData.append('linkedin_url', values.linkedin_url)
      formData.append('twitter_url', values.twitter_url);
      formData.append('current_title', values.current_title);
      formData.append('current_company', values.current_company);
      formData.append('biography', biography);
      if(values.profile_picture !== "" || values.profile_picture !== null){
        formData.append('profile_picture', values.profile_picture);
      }else{
        formData.append('profile_picture', avatar);
      }
     

      dispatch(profileUpdate(formData))
    },
  })

  const handleAction = (id, status) => {
    const data = {
      request_id: id,
      status: status,
    }
    dispatch(requestAction(data))
  }

  const handleProfileDelete = () => {
    dispatch(profileDelete())
  }

  useEffect(()=> {
    setDeleteProfileStatus(deleteProfileData)
  },[deleteProfileData])

  useEffect(()=> {
    setUpdateProfileStatus(updateProfileData)
  },[updateProfileData])

  return (
    <React.Fragment>
      {/* <div className="page-content">
        <Container fluid>
          
          <Breadcrumb title="Skote" breadcrumbItem="Update Profile" />
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}
            </Col>
          </Row>
          
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">First Name</Label>
                      <Input
                        name="first_name"
                        // value={name}
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.first_name || ""}
                        invalid={
                          validation.touched.first_name &&
                          validation.errors.first_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.first_name &&
                      validation.errors.first_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.first_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Last Name</Label>
                      <Input
                        name="last_name"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.last_name || ""}
                        invalid={
                          validation.touched.last_name &&
                          validation.errors.last_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.last_name &&
                      validation.errors.last_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.last_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Email Address"
                        type="email"
                        readOnly
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Linkedin URL</Label>
                      <Input
                        name="linkedin_url"
                        // value={name}
                        className="form-control"
                        placeholder="Enter linkedin url"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.linkedin_url || ""}
                        invalid={
                          validation.touched.linkedin_url &&
                          validation.errors.linkedin_url
                            ? true
                            : false
                        }
                      />
                      {validation.touched.linkedin_url &&
                      validation.errors.linkedin_url ? (
                        <FormFeedback type="invalid">
                          {validation.errors.linkedin_url}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Twitter URL</Label>
                      <Input
                        name="twitter_url"
                        // value={name}
                        className="form-control"
                        placeholder="Enter twitter url"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.twitter_url || ""}
                        invalid={
                          validation.touched.twitter_url &&
                          validation.errors.twitter_url
                            ? true
                            : false
                        }
                      />
                      {validation.touched.twitter_url &&
                      validation.errors.twitter_url ? (
                        <FormFeedback type="invalid">
                          {validation.errors.twitter_url}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Current Company</Label>
                      <Input
                        name="current_company"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Current company"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.current_company || ""}
                        invalid={
                          validation.touched.current_company &&
                          validation.errors.current_company
                            ? true
                            : false
                        }
                      />
                      {validation.touched.current_company &&
                      validation.errors.current_company ? (
                        <FormFeedback type="invalid">
                          {validation.errors.current_company}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6" className="mt-3">
                    <div className="form-group">
                      <Label className="form-label">Current Designation</Label>
                      <Input
                        name="current_title"
                        // value={name}
                        className="form-control"
                        placeholder="Enter Current Designation"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.current_title || ""}
                        invalid={
                          validation.touched.current_title &&
                          validation.errors.current_title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.current_title &&
                      validation.errors.current_title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.current_title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  
                  {details && details.is_advisory == 1 ? (
                    <>
                      <Col lg="6" className="mt-3">
                        <div className="form-group">
                          <Label className="form-label">Skills</Label>
                          <Select
                            value={skilllist}
                            isMulti={true}
                            onChange={e => {
                              handleSkills(e)
                            }}
                            options={optins}
                            classNamePrefix="select2-selection"
                          />

                          {validation.touched.skills &&
                          validation.errors.skills ? (
                            <FormFeedback type="invalid">
                              {validation.errors.skills}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg="6" className="mt-3">
                        <div className="form-group">
                          <Label className="form-label">Industry</Label>
                        

                          <Select
                            value={industrylist}
                            isMulti={true}
                            onChange={e => {
                              handleIndustry(e)
                            }}
                     ￼
￼￼Choose fileNo file chosen
Name*￼
Current Company*￼
Current designation*￼
Phone Number
￼
￼
￼
What are you looking for?     {validation.touched.skills &&
                          validation.errors.skills ? (
                            <FormFeedback type="invalid">
                              {validation.errors.skills}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg="12" className="mt-3">
                        <div className="form-group">
                          <Label className="form-label">Biography</Label>
                          <CKEditor
                            id="inputText"
                            data={details ? details.biography : ""}
                            className="inputText"
                            editor={ClassicEditor}
                            value={validation.values.biography || ""}
                            onChange={inputHandler}
                          />
                          {validation.touched.biography &&
                          validation.errors.biography ? (
                            <FormFeedback type="invalid">
                              {validation.errors.biography}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>{" "}
                    </>
                  ) : null}
                </Row>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Profile
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div> */}

<div className="overlay"></div>
    <div className="mt-4">
        <div className="dashaboard-content">
            <div className="dashaboard-inner">
               <Row>
                <Col lg="12">
                  {error ? 
                    <Alert color="danger">{error}</Alert>
                  : null}
                  {/* {success ? 
                    <Modal isOpen={isModalOpen} toggle={()=>setIsModalOpen(false)} centered>
                      <ModalBody>
                        <div className="content-area-modal text-center">
                                  <img src="images/icon-success.svg" alt="" className="img" />
                                  <h2>Success</h2>
                                  <p> {success} </p>
                                  <button type="button" className="btn btn-primary btn-green" onClick={()=>setIsModalOpen(false)}>OK</button>
                                </div>
                      </ModalBody>
                    </Modal>
                  : null} */}
                </Col>
               </Row>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-items">
                        <div className="accordion-header" id="headingOne">
                            <div className="accordion-button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Update Profile
                            </div>
                        </div>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body bg-white">
                                <div className="row">
                                    <div className="col-md-3 col-xxl-2 order-md-2">
                                        <div className="user-profile-auth text-center">
                                            <div className="profile-img">
                                                {/* <img draggable="false" src={avatar} alt="" /> */}
                                                {
                                                  details.profile_picture || avatarURL ? 
                                                  avatarURL ?
                                                    <img src={avatarURL} alt="avatar" />
                                                    : 
                                                    <img draggable="false" src={API_URL+`profilePicture/${details.profile_picture}`} alt="" />
                                                  :
                                                  <img src={avatarImg} alt="avatar" />
                                                }
                                               
                                               {
                                                (details.profile_picture || avatarURL) &&
                                                <a href="javascript:void(0)" onClick={()=> {
                                                  setAvatarURL(null)
                                                  setAvatar(null)
                                                  handleProfileDelete()
                                                }} className="btn-delete">
                                                     <img src="images/icon-delete.svg" alt="" />
                                                </a>
                                               }
                                                
                                            </div>
                                            <p> {details.first_name} {details.last_name} </p>
                                            <div className="uploadbtn position-relative">
                                                <input 
                                                 type="file"
                                                 name="avtar"
                                                 accept="image/*"
                                                 onChange={handleImageChange}
                                                 />
                                                <a href="" className="btn btn-primary btn-fill">Upload Image</a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-9 col-xxl-10">
                                      <Form
                                        className="form-horizontal"
                                        onSubmit={e => {
                                          e.preventDefault()
                                          validation.handleSubmit()
                                          return false
                                        }}
                                      >
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="position-relative input-single">
                                                    <label className="form-label">Full Name</label>
                                                    <input type="text"
                                                      name="first_name"
                                                      className="form-control"
                                                      placeholder="Enter Name"
                                                      onChange={validation.handleChange}
                                                      onBlur={validation.handleBlur}
                                                      value={validation.values.first_name || ""}
                                                      invalid={
                                                        validation.touched.first_name &&
                                                        validation.errors.first_name
                                                          ? true
                                                          : false
                                                      }
                                                      />
                                                      {validation.touched.first_name &&
                                                      validation.errors.first_name ? (
                                                        <FormFeedback type="invalid">
                                                          {validation.errors.first_name}
                                                        </FormFeedback>
                                                      ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="position-relative input-single">
                                                    <label className="form-label">Email</label>
                                                    <input
                                                     type="email"
                                                     className="form-control"
                                                     placeholder="advisoryadmin@advisory.com"
                                                     readOnly
                                                     name="email"
                                                     onChange={validation.handleChange}
                                                      onBlur={validation.handleBlur}
                                                      value={validation.values.email || ""}
                                                      invalid={
                                                        validation.touched.email && validation.errors.email
                                                          ? true
                                                          : false
                                                      }
                                                     />
                                                      {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">
                                                          {validation.errors.email}
                                                        </FormFeedback>
                                                      ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="position-relative input-single">
                                                    <label className="form-label">Twitter URL</label>
                                                    <input 
                                                      name="twitter_url"
                                                      type="text" 
                                                      className="form-control"
                                                      placeholder="Enter twitter URL"
                                                      onChange={validation.handleChange}
                                                      onBlur={validation.handleBlur}
                                                      value={validation.values.twitter_url || ""}
                                                      invalid={
                                                        validation.touched.twitter_url &&
                                                        validation.errors.twitter_url
                                                          ? true
                                                          : false
                                                      }
                                                      />
                                                      {validation.touched.twitter_url &&
                                                      validation.errors.twitter_url ? (
                                                        <FormFeedback type="invalid">
                                                          {validation.errors.twitter_url}
                                                        </FormFeedback>
                                                      ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="position-relative input-single">
                                                    <label className="form-label">LinkedIn URL</label>
                                                    <input 
                                                      name="linkedin_url"
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter linkedin URL"
                                                      onChange={validation.handleChange}
                                                      onBlur={validation.handleBlur}
                                                      value={validation.values.linkedin_url || ""}
                                                      invalid={
                                                        validation.touched.linkedin_url &&
                                                        validation.errors.linkedin_url
                                                          ? true
                                                          : false
                                                      }
                                                      />
                                                       {validation.touched.linkedin_url &&
                                                        validation.errors.linkedin_url ? (
                                                          <FormFeedback type="invalid">
                                                            {validation.errors.linkedin_url}
                                                          </FormFeedback>
                                                        ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="position-relative input-single">
                                                    <label className="form-label">Current Company</label>
                                                    <input
                                                     name="current_company"
                                                     type="text" 
                                                     className="form-control"
                                                     placeholder="Enter Current Company"
                                                     onChange={validation.handleChange}
                                                     onBlur={validation.handleBlur}
                                                     value={validation.values.current_company || ""}
                                                     invalid={
                                                      validation.touched.current_company &&
                                                      validation.errors.current_company
                                                        ? true
                                                        : false
                                                     }
                                                     />
                                                      {validation.touched.current_company &&
                                                      validation.errors.current_company ? (
                                                        <FormFeedback type="invalid">
                                                          {validation.errors.current_company}
                                                        </FormFeedback>
                                                      ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="position-relative input-single">
                                                    <label className="form-label">Current Title</label>
                                                    <input 
                                                      name="current_title"
                                                      type="text" 
                                                      className="form-control"
                                                      placeholder="Enter Current Title"
                                                      onChange={validation.handleChange}
                                                      onBlur={validation.handleBlur}
                                                      value={validation.values.current_title || ""}
                                                      invalid={
                                                        validation.touched.current_title &&
                                                        validation.errors.current_title
                                                          ? true
                                                          : false
                                                      }
                                                      />
                                                        {validation.touched.current_title &&
                                                        validation.errors.current_title ? (
                                                          <FormFeedback type="invalid">
                                                            {validation.errors.current_title}
                                                          </FormFeedback>
                                                        ) : null}
                                                </div>
                                            </div>
                                            {
                                              details && details.is_advisory == 1 ? (
                                                <>
                                                    <div className="col-md-12 mb-4">
                                                        <div className="position-relative input-single">
                                                            <label className="form-label">Biography</label>
                                                            {/* <textarea 
                                                             className="form-control"
                                                             placeholder="Write here"
                                                             value={validation.values.biography || ""}
                                                             onChange={inputHandler}
                                                             ></textarea> */}
                                                               <CKEditor
                                                                id="inputText"
                                                                data={details ? details.biography : ""}
                                                                className="inputText"
                                                                editor={ClassicEditor}
                                                                value={validation.values.biography || ""}
                                                                onChange={inputHandler}
                                                              />
                                                            {validation.touched.biography &&
                                                            validation.errors.biography ? (
                                                              <FormFeedback type="invalid">
                                                                {validation.errors.biography}
                                                              </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-5">
                                                        <div className="sec-add-skills ">
                                                            <h3>Skills</h3>
                                                            <Select
                                                              value={skilllist}
                                                              isMulti={true}
                                                              onChange={e => {
                                                                handleSkills(e)
                                                              }}
                                                              options={optins}
                                                              classNamePrefix="select2-selection"
                                                            />
                                                            {validation.touched.skills &&
                                                            validation.errors.skills ? (
                                                              <FormFeedback type="invalid">
                                                                {validation.errors.skills}
                                                              </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </>
                                              ) : null
                                            }
                                            
                                            <div className="col-md-12">
                                                <div className="sec-add-skills">
                                                    <button type="submit" className="btn btn-primary btn-fill" data-bs-toggle="modal" data-bs-target="#SuccessModal">Update
                                                        Profile</button>
                                                </div>
                                            </div>
                                        </div>
                                      </Form>  
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                      isAdvisory === 1 &&                       
                      <div className="accordion-items">
                          <div className="accordion-header" id="headingTwo">
                              <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                  aria-expanded="false" aria-controls="collapseTwo">
                                  Advisory Request Receive
                              </div>
                          </div>
                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample">
                              <div className="accordion-body bg-white">
                                  <div className="vertically-scroll">
                                      <div className="table-responsive">
                                          <table className="table table-dashboard table-requests-sent m-0">
                                              <tbody>
                                                {
                                                    requestData && requestData.length > 0 ?
                                                    requestData.map((req,i)=>{
                                                      return(
                                                        <tr key={i}>
                                                          <td>
                                                              <div className="d-flex align-items-center">
                                                              <img src="images/avatar-1.jpg" alt="" />
                                                                  <div className="flex-1 ms-3">
                                                                      <h6 className="mb-1"><a href="#">{req.requested_by_user.first_name} {req.requested_by_user.last_name} </a></h6>
                                                                  </div>
                                                              </div>
                                                          </td>
                                                          <td>
                                                              <p>{req.requested_by_user.email}</p>
                                                          </td>
                                                          <td>
                                                              <div className="d-flex justify-content-end">
                                                                  <a href="" className="btn btn-outline me-2"
                                                                    onClick={() =>
                                                                      handleAction(req.id, 2)
                                                                    }
                                                                  >Decline</a>
                                                                  <a href="" className="btn btn-primary btn-fill ms-2" 
                                                                    onClick={() =>
                                                                      handleAction(req.id, 1)
                                                                    }
                                                                  >Accept</a>
                                                              </div>
                                                          </td>
                                                      </tr>
                                                      )
                                                    })
                                                    :
                                                    null
                                                }
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    }
                </div>
            </div>

            {/* delete profile */}
            {deleteProfileStatus?.status ? 
                    <Modal isOpen={isModalOpen2} toggle={()=>setIsModalOpen2(false)} centered>
                      <ModalBody>
                        <div className="content-area-modal text-center">
                                  <img src="images/icon-success.svg" alt="" className="img" />
                                  <h2>Success</h2>
                                  <p> {deleteProfileStatus?.message} </p>
                                  <button type="button" className="btn btn-primary btn-green" onClick={()=>{
                                    setIsModalOpen2(false)
                                    window.location.reload()
                                  }}>OK</button>
                                </div>
                      </ModalBody>
                    </Modal>
            : null}

            {/* for update profile */}
             {updateProfileStatus?.status ? 
                    <Modal isOpen={isModalOpen3} toggle={()=>setIsModalOpen3(false)} centered>
                      <ModalBody>
                        <div className="content-area-modal text-center">
                                  <img src="images/icon-success.svg" alt="" className="img" />
                                  <h2>Success</h2>
                                  <p> {updateProfileStatus?.message} </p>
                                  <button type="button" className="btn btn-primary btn-green" onClick={()=>{
                                    setIsModalOpen3(false)
                                    window.location.reload()
                                  }}>OK</button>
                                </div>
                      </ModalBody>
                    </Modal>
            : null}



            <footer className="footer">
                <div className="col-md-12">
                    <div className="">
                        <p className="m-0">2020 © Advisory.</p>
                    </div>
                </div>
            </footer>
        </div>
    </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
