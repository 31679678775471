export const MYADVISOR = "MYADVISOR"
export const MYADVISORSUCCESS = "MYADVISORSUCCESS"
export const MYADVISORERROR = "MYADVISORERROR"

export const SEARCHMYADVISOR = "SEARCHMYADVISOR"
export const SEARCHMYADVISORSUCCESS = "SEARCHMYADVISORSUCCESS"
export const SEARCHMYADVISORERROR = "SEARCHMYADVISORERROR"
export const SEARCHSAVEDADVISOR = "SEARCHSAVEDADVISOR"
export const SEARCHSAVEDADVISORSUCCESS = "SEARCHSAVEDADVISORSUCCESS"
export const SEARCHSAVEDADVISORERROR = "SEARCHSAVEDADVISORERROR"

export const ADVISORLIST = "ADVISORLIST"
export const ADVISORLISTSUCCESS = "ADVISORLISTSUCCESS"
export const ADVISORLISTERROR = "ADVISORLISTERROR"

export const SAVEDADVISOR = "SAVEDADVISOR"
export const SAVEDADVISORSUCCESS = "SAVEDADVISORSUCCESS"
export const SAVEDADVISORERROR = "SAVEDADVISORERROR"

export const SAVEADVISOR = "SAVEADVISOR"
export const SAVEADVISORSUCCESS = "SAVEADVISORSUCCESS"
export const SAVEADVISORERROR = "SAVEADVISORERROR"

export const DELETESAVEADVISOR = "DELETESAVEADVISOR"
export const DELETESAVEADVISORSUCCESS = "DELETESAVEADVISORSUCCESS"
export const DELETESAVEADVISORERROR = "DELETESAVEADVISORERROR"

export const FILTERADVISOR = "FILTERADVISOR"
export const FILTERADVISORSUCCESS = "FILTERADVISORSUCCESS"
export const FILTERADVISORERROR = "FILTERADVISORERROR"

export const MEETINGREQUEST = "MEETINGREQUEST"
export const MEETINGREQUESTSUCCESS = "MEETINGREQUESTSUCCESS"
export const MEETINGREQUESTError = "MEETINGREQUESTError"

export const JOINREQUEST = "JOINREQUEST"
export const JOINREQUESTSUCCESS = "JOINREQUESTSUCCESS"
export const JOINREQUESTError = "JOINREQUESTError"

export const REQUESTLIST = "REQUESTLIST"
export const REQUESTLISTSUCCESS = "REQUESTLISTSUCCESS"
export const REQUESTLISTError = "REQUESTLISTError"

export const REQUESTACTION = "REQUESTACTION"
export const REQUESTACTIONSUCCESS = "REQUESTACTIONSUCCESS"
export const REQUESTACTIONError = "REQUESTACTIONError"

export const REQUEST_SENT = "REQUEST_SENT"
export const REQUEST_SENT_SUCCESS = "REQUEST_SENT_SUCCESS"
export const REQUEST_SENT_FAIL = "REQUEST_SENT_FAIL"

export const BECOME_ADVISOR = "BECOME_ADVISOR"
export const BECOME_ADVISOR_SUCCESS = "BECOME_ADVISOR_SUCCESS"
export const BECOME_ADVISOR_FAIL = "BECOME_ADVISOR_FAIL"
