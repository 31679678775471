import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap"
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import profileImg from "../../../assets/images/profile-img.png"
import avatar1 from "../../../assets/images/users/avatar-1.jpg"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  savedAdvisor,
  searchSavedAdvisorList,
  meetingRequest,
  filterAdvisor,
  joinRequest,
  getSkills
} from "store/actions"
import Select from "react-select"


const SavedAdvisor = () => {
  const [menu, setMenu] = useState(false)
  const dispatch = useDispatch()

  const { advisors, skill } = useSelector(
    state => ({
        advisors: state.MyAdvisor.saved,
        skill: state.Skills.data,
      })
  )


  const [skilllist, setSelectedSkills] = useState([])
  var optins = []
  if (skill && skill.length > 0) {
    for (let sk of skill) {
      const data = { value: sk.id, label: sk.name }
      optins.push(data)
    }
  }

  function handleSkills(selectedMulti) {
    setSelectedSkills(selectedMulti)
    //setSkillData()
  }


  const meetingReqest = data => {
    dispatch(meetingRequest(data.id))
  }

  const inviteRequest = data => {
    const d = {
      user_id: data.id,
    }
    dispatch(joinRequest(d))
  }
 
  const searchAdvisor = keyword => {
    if (keyword != "") {
      const data = { name: keyword }
      dispatch(searchSavedAdvisorList(data))
    } else {
      dispatch(savedAdvisor())
    }
  }

  useEffect(() => {
    dispatch(savedAdvisor())
    dispatch(getSkills())
  }, [])

  //meta title
  document.title = "Saved Advisors | Advisory Dashboard"

  return (
    <React.Fragment>
      {/* <div className="page-content">
        <Container fluid>
         
          <Breadcrumbs title="Advisors" breadcrumbItem="Saved Advisors" />
        </Container>
        <Row>
          <Col xl="12">
            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={e => searchAdvisor(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
          </Col>
        </Row>
        <Row>
          <Col xl="12" sm="12">
            {advisors && advisors.length > 0 ? (
              advisors.map((advisor, index) => {
                console.log(advisor)
                return (
                  <Card className="overflow-hidden" key={index}>
                    <CardBody className="pt-3">
                      <div className="d-flex">
                        <div className="avatar-md me-4">
                          <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                            <img src={profileImg} alt="" height="50" />
                          </span>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="text-truncate font-size-15">
                            {advisor.advisor
                              ? advisor.advisor.first_name
                              : advisor.first_name}{" "}
                            {advisor.advisor
                              ? advisor.advisor.last_name
                              : advisor.last_name}
                          </h5>
                          <p className="text-muted">
                            {advisor.advisor
                              ? advisor.advisor.email
                              : advisor.email}
                          </p>
                          <p className="text-muted">
                            {advisor.advisor
                              ? advisor.advisor.current_title
                              : advisor.current_title}
                          </p>
                        </div>
                        {advisor.my_advisor &&
                        advisor.my_advisor != null &&
                        advisor.my_advisor.status != 2 ? (
                          <div className="float-right overflow-hidden">
                            {advisor.my_advisor.status == 0 ? (
                              <p className="text-info">
                                <b>Pending</b>
                              </p>
                            ) : advisor.my_advisor.status == 1 ? (
                              <p className="text text-success">Approved</p>
                            ) : (
                              <p className="text text-danger">Declined</p>
                            )}
                          </div>
                        ) : (
                         
                          <Dropdown
                            isOpen={menu[index]}
                            toggle={() =>
                              setMenu({ ...menu, [index]: !menu[index] })
                            }
                            className="d-inline-block"
                          >
                            <DropdownToggle
                              className="btn header-item "
                              id="page-header-user-dropdown"
                              tag="button"
                            >
                              <span className="d-none d-xl-inline-block ms-2 me-1 btn btn-info">
                                Actions{" "}
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                              </span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              
                              <DropdownItem>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    meetingReqest(
                                      advisor.advisor
                                        ? advisor.advisor
                                        : advisor
                                    )
                                  }
                                >
                                  Request Meeting
                                </Link>
                              </DropdownItem>
                              <div className="dropdown-divider" />
                              <DropdownItem>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    inviteRequest(
                                      advisor.advisor
                                        ? advisor.advisor
                                        : advisor
                                    )
                                  }
                                >
                                  Invite to Join
                                </Link>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                          // </div>
                        )}
                      </div>
                      <div>
                        {advisor.advisor &&
                        advisor.advisor.skills &&
                        advisor.advisor.skills.length > 0 ? (
                          <p>
                            {" "}
                            Skills :
                            {advisor.advisor.skills.map((x, index) => {
                              return (
                                <span key={index}>
                                  {" "}
                                  {x.name} {", "}
                                </span>
                              )
                            })}
                          </p>
                        ) : null}
                        {advisor.advisor &&
                        advisor.advisor.industries &&
                        advisor.advisor.industries.length > 0 ? (
                          <p>
                            {" "}
                            Industry :
                            {advisor.advisor.industries.map((x, index) => {
                              return (
                                <span key={index}>
                                  {x.name} {", "}
                                </span>
                              )
                            })}
                          </p>
                        ) : null}
                        <p>
                          Linkedin -{" "}
                          {advisor.advisor
                            ? advisor.advisor.linkedin_url
                            : advisor.linkedin_url}
                        </p>
                        <p>
                          Twitter -{" "}
                          {advisor.advisor
                            ? advisor.advisor.twitter_url
                            : advisor.twitter_url}
                        </p>
                        <h6 className="text-muted mb-4 mt-3">Biography</h6>
                        <p className="mb-4">
                          {" "}
                          {advisor.advisor
                            ? ReactHtmlParser(advisor.advisor.biography)
                            : ReactHtmlParser(advisor.biography)}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                )
              })
            ) : (
              <Card className="overflow-hidden">
                <CardBody className="pt-3">
                  <h3 className="text text-info text-center">
                    You don't have any saved advisor.
                  </h3>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div> */}

<div className="overlay"></div>
    <div className="">
        <div className="dashaboard-content">
            <div className="dashaboard-inner">
                <div className="sec-search-advisory ">
                    <div className="card-section-title mb-3">
                        <h2>SAVED ADVISORS</h2>
                    </div>
                    <div className="row align-items-end">
                        <div className="col-md-8 mb-4">
                            <div className="form-group">
                                <div className="search-area">
                                    <input type="text" className="form-control" placeholder="Search..." onChange={e => searchAdvisor(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="search-skills-area">
                                <div className="form-group">
                                    <label>Skills <i className="mdi mdi-chevron-down"></i></label>
                                    {/* <select id="lang" name="lang" className="form-control select-custom" data-dropup-auto="false" data-live-search="true" multiple>
                                        <option value="1">Engineering</option>
                                        <option value="1">Operations Management</option>
                                        <option value="2">Operations Management Head</option>
                                        <option value="3">Operations Team</option>
                                      </select> */}
                                      <Select
                                        value={skilllist}
                                        isMulti={true}
                                        onChange={e => {
                                          handleSkills(e)
                                        }}
                                        options={optins}
                                        classNamePrefix="select2-selection"
                                      />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sec-advisory-row">
                {advisors && advisors.length > 0 ? (
                  advisors.map((advisor, index) => {
                  return (
                      <div key={index} className="card-advisory-itam d-flex flex-wrap bg-white mb-4">
                          <div className="cardImg d-flex align-items-center">
                              <img src={profileImg} alt="" />
                              <div className="card-info">
                                  <h5> {advisor.advisor
                                    ? advisor.advisor.first_name
                                    : advisor.first_name}{" "}
                                  {advisor.advisor
                                    ? advisor.advisor.last_name
                                    : advisor.last_name}</h5>
                                  <p> {advisor.advisor
                                    ? advisor.advisor.current_title
                                    : advisor.current_title}</p>
                                  <ul className="d-flex align-items-center link-media">
                                      <li><a href={advisor.linkedin_url}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                                              <path data-name="Icon awesome-linkedin-in" d="M2.238,10H.165V3.324H2.238ZM1.2,2.413A1.206,1.206,0,1,1,2.4,1.2,1.211,1.211,0,0,1,1.2,2.413ZM10,10H7.929V6.751c0-.775-.016-1.768-1.078-1.768-1.078,0-1.243.842-1.243,1.712V10H3.537V3.324H5.526v.911h.029A2.179,2.179,0,0,1,7.516,3.157C9.614,3.157,10,4.539,10,6.333V10Z" transform="translate(0 -0.001)" fill="#495057"/>
                                            </svg>
                                            
                                      </a></li>
                                      <li><a href={advisor.twitter_url}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="12.066" height="10" viewBox="0 0 12.066 10">
                                              <path data-name="Icon awesome-twitter" d="M10.826,5.873c.008.109.008.219.008.328A7.065,7.065,0,0,1,3.8,13.381,6.887,6.887,0,0,1,0,12.248a5.014,5.014,0,0,0,.6.031A4.89,4.89,0,0,0,3.667,11.2a2.484,2.484,0,0,1-2.312-1.75,3.058,3.058,0,0,0,.467.039A2.566,2.566,0,0,0,2.473,9.4,2.512,2.512,0,0,1,.49,6.928V6.9a2.453,2.453,0,0,0,1.118.32A2.561,2.561,0,0,1,.842,3.842a6.984,6.984,0,0,0,5.1,2.641A2.9,2.9,0,0,1,5.88,5.9,2.5,2.5,0,0,1,8.353,3.381a2.444,2.444,0,0,1,1.807.8,4.81,4.81,0,0,0,1.57-.609,2.508,2.508,0,0,1-1.087,1.391,4.879,4.879,0,0,0,1.424-.391,5.376,5.376,0,0,1-1.24,1.3Z" transform="translate(0 -3.381)" fill="#495057"/>
                                            </svg>
                                            
                                      </a></li>
                                  </ul>
                              </div>
                          </div>
                          <div className="card-caption">
                              <div className="col px-4">
                                  <h5>Biography</h5>
                                  <p>{advisor.advisor
                            ? ReactHtmlParser(advisor.advisor.biography)
                            : ReactHtmlParser(advisor.biography)}</p>
                                      <a href="javascript:void(0)" className="btn btn-primary btn-fill"
                                         onClick={() =>
                                          meetingReqest(
                                            advisor.advisor
                                              ? advisor.advisor
                                              : advisor
                                          )
                                        }
                                      >Request Meeting</a>
                              </div>
                              <div className="card-action ml-auto">
                                { advisor.my_advisor &&
                                    advisor.my_advisor != null &&
                                    advisor.my_advisor.status != 2 ?
                                    <div className="float-right overflow-hidden">
                                    {advisor.my_advisor.status == 0 ? (
                                      <p className="text-info">
                                        <b>Pending</b>
                                      </p>
                                    ) : advisor.my_advisor.status == 1 ? (
                                      <p className="text text-success">Approved</p>
                                    ) : (
                                      <p className="text text-danger">Declined</p>
                                    )}
                                  </div> :
                                  <div className="btn-group">
                                      <button type="button" className="btn btn-primary btn-fill dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                          Actions <i className="mdi mdi-chevron-down"></i>
                                      </button>
                                      <ul className="dropdown-menu dropdown-menu-end">
                                        <li><a className="dropdown-item" href="#">Send Request</a></li>
                                        <li><a className="dropdown-item" href="#"
                                          onClick={() =>
                                            meetingReqest(
                                              advisor.advisor
                                                ? advisor.advisor
                                                : advisor
                                            )
                                          }
                                        >Request Meeting</a></li>
                                        <li><a className="dropdown-item" href="#"
                                          onClick={() =>
                                            inviteRequest(
                                              advisor.advisor
                                                ? advisor.advisor
                                                : advisor
                                            )
                                          }
                                        >Invite to Join</a></li>
                                        <li><a className="dropdown-item" href="#">Save Advisory</a></li>
                                      </ul>
                                    </div>
                                }
                              </div>
                          </div>
                      </div>
                  )
                })
                ) : (
                  <Card className="overflow-hidden">
                    <CardBody className="pt-3">
                      <h3 className="text text-info text-center">
                        You don't have any saved advisor.
                      </h3>
                    </CardBody>
                  </Card>
                )}
                </div>

            </div>
            <footer className="footer">
                <div className="col-md-12">
                    <div className="">
                        <p className="m-0">2020 © Advisory.</p>
                    </div>
                </div>
            </footer>
        </div>
    </div>
    </React.Fragment>
  )
}

export default SavedAdvisor
