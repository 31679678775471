import {
  ADD_NEW_INDUSTRY,
  ADD_NEW_INDUSTRY_SUCCESS,
  ADD_SNEW_INDUSTRY_ERROR,
  DELETE_INDUSTRY,
  DELETE_INDUSTRY_ERROR,
  DELETE_INDUSTRY_USCCESS,
  EDIT_INDUSTRY_ERROR,
  EDIT_INDUSTRY_SUCCESS,
  INDUSTRY_LIST_ERROR,
  INDUSTRY_LIST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  error: "",
  success: "",
  data: [],
}

const Industry = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INDUSTRY_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
      }
    case INDUSTRY_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_INDUSTRY_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case ADD_SNEW_INDUSTRY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case EDIT_INDUSTRY_SUCCESS:
      return { ...state, success: action.payload }
    case EDIT_INDUSTRY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_INDUSTRY_USCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case DELETE_INDUSTRY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Industry
