module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "291885940323-d47p5p8jb9ujccqdkmhk6e6i274md3rv.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
}
