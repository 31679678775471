export const USERS_LIST = "USERS_LIST"
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS"
export const USERS_LIST_ERROR = "USERS_LIST_ERROR"

export const USER_ACTION = "USER_ACTION"
export const USER_ACTION_SUCCESS = "USER_ACTION_SUCCESS"
export const USER_ACTION_ERROR = "USER_ACTION_ERROR"

export const PROFILE = "PROFILE"
export const PROFILESUCCESS = "PROFILESUCCESS"
export const PROFILEERROR = "PROFILEERROR"

export const UPDATEPROFILE = "UPDATEPROFILE"
export const UPDATEPROFILESUCCESS = "UPDATEPROFILESUCCESS"
export const UPDATEPROFILEERROR = "UPDATEPROFILEERROR"

export const PROFLE_DELETE = "PROFLE_DELETE"
export const PROFILE_DELETE_SUCCESS = "PROFILE_DELETE_SUCCESS"
export const PROFILE_DELETE_ERROR = "PROFILE_DELETE_ERROR"