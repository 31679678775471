import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

const Register = props => {
  //meta title
  document.title = "Register | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const [is_advisor, setMyAdvisor] = useState(0)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      is_advisor: 0,
    },
    validationSchema: Yup.object({
      email: Yup.string()
      .required("Please Enter Your Email")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        "Invalid email format"
      ),
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      values.is_advisory = is_advisor ? 1 : 0
      values.register_As = is_advisor ? 2 : 1
      console.log(values)
      // dispatch(registerUser(values, props.history))
    },
  })

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free Advisory account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="first_name"
                          type="text"
                          placeholder="Enter First Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.first_name &&
                        validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="last_name"
                          type="text"
                          placeholder="Enter Last Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                        validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_advisor"
                          onClick={() => setMyAdvisor(!is_advisor)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_advisor"
                        >
                          Wants to register as Advisor
                        </label>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Advisory{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Advisory. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by CIS
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
       <div className="form-signin d-flex align-items-center justify-content-center min-vh-100 mx-auto flex-column">
          <div className="card-plain">
            <div className="shadow">
              <div className="card-plain-header position-relative" style={{backgroundImage: 'url(./images/sign-illustration.png)'}}>
                <h3 className="font-weight-medium">Welcome Back !</h3>
                <p className="mb-0">Sign Up to continue to Advisory</p>
              </div>
              <div className="card-plain-body bg-white">
                <div className="form-signin-logo">
                    <img src="images/form-logo.jpg" alt="" />
                </div>
                <Form
                    className="form-horizontal"
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        // return false
                    }}
                 >
                   {/* {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                    ) : null} */}
                  <div className="mb-3">
                    <div className="position-relative input-single">
                      <label className="form-label">Email</label>
                      <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                         {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      <span className="filed-icon"><img draggable="false" src="images/icon-email.svg" alt="" /></span>
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="position-relative input-single">
                      <label className="form-label">First Name</label>
                      <Input
                          name="first_name"
                          type="text"
                          placeholder="Enter First Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                       {validation.touched.first_name &&
                        validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      {/* <span className="filed-icon"><img draggable="false" src="images/icon-eye.svg" alt="" /></span> */}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="position-relative input-single">
                      <label className="form-label">Last Name</label>
                      <Input
                          name="last_name"
                          type="text"
                          placeholder="Enter Last Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                        validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      {/* <span className="filed-icon"><img draggable="false" src="images/icon-eye.svg" alt="" /></span> */}
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="position-relative input-single">
                      <label className="form-label">Password</label>
                      <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                       {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      <span className="filed-icon"><img draggable="false" src="images/icon-eye.svg" alt="" /></span>
                    </div>
                  </div>

                  <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_advisor"
                          onClick={() => setMyAdvisor(!is_advisor)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_advisor"
                        >
                          Wants to register as Advisor
                        </label>
                      </div>

                  <div className="mb-4">
                    <div className="position-relative">
                      <button type="submit" className="btn btn-primary btn-fill w-100">Sign UP</button>
                    </div>
                  </div>

                  <div className="position-relative text-center">
                    <p className="font-weight-medium">
                      Sign In using
                    </p>
                    <div className="social-link">
                      <ul className="d-flex align-items-center justify-content-center">
                        <li><a href="" className="icon-in">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.787" height="10.786">
                              <path fill="#fff"
                                d="M2.414 10.786H.178v-7.2h2.236ZM1.3 2.599a1.3 1.3 0 1 1 1.29-1.3 1.306 1.306 0 0 1-1.29 1.3Zm9.489 8.184H8.553V7.28c0-.835-.017-1.907-1.163-1.907-1.163 0-1.341.908-1.341 1.847v3.566H3.815v-7.2H5.96v.982h.031a2.35 2.35 0 0 1 2.116-1.169c2.263 0 2.679 1.49 2.679 3.426v3.956Z"
                                data-name="Icon awesome-linkedin-in" />
                            </svg>
                          </a></li>
                        <li><a href="" className="icon-google">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13">
                              <defs>
                                <clipPath id="a">
                                  <path fill="none" d="M0 0h13v13H0z" />
                                </clipPath>
                              </defs>
                              <path fill="none" d="M0 0h13v13H0z" data-name="Background" />
                              <g clipPath="url(#a)" data-name="google">
                                <path fill="#fff"
                                  d="M11.565 6.012H6.6v1.479h3.524A3.267 3.267 0 0 1 6.6 10.438 3.884 3.884 0 0 1 2.709 6.5a3.882 3.882 0 0 1 3.9-3.938 3.807 3.807 0 0 1 2.654 1.067l1.029-1.073a5.238 5.238 0 0 0-3.737-1.473A5.508 5.508 0 0 0 1.1 6.5a5.484 5.484 0 0 0 5.536 5.417 4.761 4.761 0 0 0 5.01-4.924 4.881 4.881 0 0 0-.081-.98Z" />
                              </g>
                            </svg>
                          </a></li>
                      </ul>
                    </div>
                    <p className="mb-0 text-lead">
                      By registering you agree to the Advisory
                      <a href="" className="text-link"> Terms of Use</a>
                    </p>
                  </div>

                </Form>
              </div>

            </div>

            <div className="card-plain-footer text-center">
              <p>Already have an account ? <Link to="/" className="text-link"> Login</Link></p>
              <p className="mb-0">2020 © Advisory.</p>
            </div>
          </div>
        </div>
    </React.Fragment>
  )
}

export default Register
