export const SKILL_LIST = "SKILL_LIST"
export const SKILL_LIST_SUCCESS = "SKILL_LIST_SUCCESS"
export const SKILL_LIST_ERROR = "SKILL_LIST_ERROR"

export const ADD_NEW_SKILL = "ADD_NEW_SKILL"
export const ADD_NEW_SKILL_SUCCESS = "ADD_NEW_SKILL_SUCCESS"
export const ADD_SNEW_SKILL_ERROR = "ADD_NEW_SKILL_ERROR"

export const EDIT_SKILL = "EDIT_SKILL"
export const EDIT_SKILL_SUCCESS = "EDIT_SKILL_SUCCESS"
export const EDIT_SKILL_ERROR = "EDIT_SKILL_ERROR"

export const DELETE_SKILL = "DELETE_SKILL"
export const DELETE_SKILL_USCCESS = "DELETE_SKILL_SUCCESS"
export const DELETE_SKILL_ERROR = "DELETE_SKILL_ERROR"
