import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { gapi } from "gapi-script"
import { useHistory } from "react-router-dom"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin, GoogleLogout } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"

import { useLinkedIn } from "react-linkedin-login-oauth2"
// You can use provided image shipped by this package or using your own
import axios from "axios"

const Login = props => {
  const history = useHistory()
  //meta title
  document.title = "Login | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: google.CLIENT_ID,
        scope: "email",
      })
    }
    gapi.load("client:auth2", start)
  }, [])

  const { linkedInLogin } = useLinkedIn({
    clientId: "788c06h6ixrox1",
    redirectUri: "http://localhost:3000/linkedin",
    onSuccess: code => {
      const body = {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: "http://localhost:3000/dashboard",
        client_id: "788c06h6ixrox1",
        client_secret: "5g81An25mKyg7rB9",
      }
      dispatch(socialLogin(body, props.history, "linkedin"))
    },
    // scope: "r_emailaddress r_liteprofile",
    onError: error => {
      console.log(error)
      setCode("")
      setErrorMessage(error.errorMessage)
    },
  })

  const onSuccess = response => {
    var name = response.profileObj.name.split(" ")
    const responce = {
      email: response.profileObj.email,
      password: "123456",
      role: "user",
      uid: response.googleId,
      first_name: name[0],
      last_name: name[1],
    }

    dispatch(socialLogin(responce, props.history, "google"))
  }
  const onFailure = response => {
    console.log("FAILED", response)
  }
  const onLogoutSuccess = () => {
    console.log("SUCESS LOG OUT")
  }

  const getlinkedininfo = async code => {
    const body = {
      grant_type: "authorization_code",
      code: code,
      redirect_uri: "http://localhost:3000/dashboard",
      client_id: "788c06h6ixrox1",
      client_secret: "5g81An25mKyg7rB9",
    }
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    }
    const response = await axios.post(
      "https://www.linkedin.com/oauth/v2/accessToken",
      axiosConfig,
      body
    )
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          User Registered Successfully!! Please login to
                          continue
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      {forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <div
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={linkedInLogin}
                            >
                              <i className="mdi mdi-linkedin" />
                            </div>
                          </li>
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={onSuccess}
                              onFailure={onFailure}
                            />
                          </li>
                        </ul>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      
      <div className="form-signin d-flex align-items-center justify-content-center min-vh-100 mx-auto flex-column">
        <div className="card-plain">
          <div className="shadow">
            <div className="card-plain-header position-relative" style={{backgroundImage: 'url(images/sign-illustration.png)'}} >
              <h3 className="font-weight-medium">Welcome Back !</h3>
              <p className="mb-0">Sign in to continue to Advisory</p>
            </div>
            <div className="card-plain-body bg-white">
              <div className="form-signin-logo">
                  <img src="images/form-logo.jpg" alt="" />
              </div>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                 {user && user ? (
                        <Alert color="success">
                          User Registered Successfully!! Please login to
                          continue
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      {forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {forgetSuccessMsg}
                        </Alert>
                      ) : null}

                  {error ? <Alert color="danger">{error}</Alert> : null}
                
                <div className="mb-3">
                  <div className="position-relative input-single">
                    <label className="form-label">Email</label>
                    <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                     {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                      ) : null}
                    <span className="filed-icon"><img draggable="false" src="images/icon-report.svg" alt="" /></span>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="position-relative input-single">
                    <label className="form-label">Password</label>
                    <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                    {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                    ) : null}
                    <span className="filed-icon"><img draggable="false" src="images/icon-eye.svg" alt="" /></span>
                  </div>
                </div>

                <div className="form-check mb-4">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Remember me
                  </label>
                </div>


                <div className="mb-4">
                  <div className="position-relative">
                    <button type="submit" className="btn btn-primary btn-fill w-100">Log In</button>
                  </div>
                </div>


                <div className="position-relative text-center">
                  <p className="font-weight-medium">
                    Sign up using
                  </p>
                  <div className="social-link">
                    <ul className="d-flex align-items-center justify-content-center">
                      <li><a href="" className="icon-in" onClick={linkedInLogin}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="10.787" height="10.786">
                            <path fill="#fff"
                              d="M2.414 10.786H.178v-7.2h2.236ZM1.3 2.599a1.3 1.3 0 1 1 1.29-1.3 1.306 1.306 0 0 1-1.29 1.3Zm9.489 8.184H8.553V7.28c0-.835-.017-1.907-1.163-1.907-1.163 0-1.341.908-1.341 1.847v3.566H3.815v-7.2H5.96v.982h.031a2.35 2.35 0 0 1 2.116-1.169c2.263 0 2.679 1.49 2.679 3.426v3.956Z"
                              data-name="Icon awesome-linkedin-in" />
                          </svg>
                        </a></li>
                      <li><a href="" className="icon-google">
                          <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={onSuccess}
                              onFailure={onFailure}
                            />
                        </a></li>
                    </ul>
                  </div>
                  <p className="mb-0 text-lead d-flex align-items-center flex-wrap justify-content-center">
                    <Link to="/forgot-password"><img src="images/icon-lock.svg" alt="" /> Forgot your password?</Link>
                  </p>
                </div>

             </Form>
            </div>

          </div>

          <div className="card-plain-footer text-center">
            <p>Don't have an account ? <Link to="/register" className="text-link">  Signup now </Link></p>
            <p className="mb-0">2020 © Advisory.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
