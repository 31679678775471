import axios from "axios"
import { del, get, post, put, postFormData } from "./api_helper"
import * as url from "./backend_url_helper"

const login = data => post(url.LOGIN, data)

const userLogin = data => post(url.USERLOGIN, data)

const register = data => post(url.REGISTER, data)

const registerInfo = data => postFormData(url.REGISTER_INFO, data)

const forgetPassword = data => post(url.ForgetPassword, data)

const resetUserPassword = data => post(url.RESTPASSWORD, data)

const googleLogin = data => post(url.GOOGLELOGIN, data)

const getProfile = () => get(url.PROFILE)

const updateProfileData = data => postFormData(url.EDITPROFILE, data)

const linkedInLogin = data => post(url.LINKEDINLOGIN, data)

const myAdvisorList = () => get(url.MYADVISOR)

const advisorsList = () => get(url.ADVISORLIST)

const savedAdvisor = () => get(url.SAVEDADVISORLIST)

const userList = () => get(url.USERLIST)

const getSkill = () => get(url.SKILLSLIST)

const addSkill = data => post(url.ADDSKILL, data)

const editSKill = data => post(url.UPDATESKILL, data)

const deleteSkills = id => post(url.DELETESKILL, id)

const getIndustryList = () => get(url.INDUSTRYLIST)

const addIndustryData = data => post(url.ADDINDUSTRY, data)

const editIndustryData = data => post(url.UPDATEINDUSTRY, data)

const deleteIndustryData = id => post(url.DELETEINDUSTRY, id)

const updateAdvisorStatus = data => post(url.UPDATEADVISORSTATUS, data)

const saveAdvisor = data => post(url.SAVEADVISOR, data)

const deleteSavedAdvisor = data => post(url.DELETESAVEDADVISOR, data)

const filterAdvisor = data => post(url.FILTERADVISOR, data)

const meetingRequestToAdvisor = id => get(url.MEETINGREQUEST + "/" + id)

const addRquest = data => post(url.ADDREQUEST, data)

const requestList = () => get(url.REQUESTLIST)

const requestSent = () => get(url.REQUEST_SENT)

const requestAction = data => post(url.REQUESTACTION, data)

const removeRequest = data => post(url.REMOVEREQUEST, data)

const searchMyAdvisor = data => post(url.SEARCHMYADVISOR, data)

const searchSavedAdvisorList = data => post(url.SEARCHSAVEDADVISOR, data)

const becomeAnAdvisor = () => get(url.BECOME_AN_ADVISOR)

const deleteProfileData = () => get(url.DELETE_PROFILE)

export {
  login,
  register,
  registerInfo,
  googleLogin,
  linkedInLogin,
  myAdvisorList,
  savedAdvisor,
  advisorsList,
  userList,
  getSkill,
  addSkill,
  editSKill,
  deleteSkills,
  updateAdvisorStatus,
  getProfile,
  saveAdvisor,
  deleteSavedAdvisor,
  filterAdvisor,
  meetingRequestToAdvisor,
  addRquest,
  resetUserPassword,
  requestList,
  requestSent,
  requestAction,
  removeRequest,
  updateProfileData,
  forgetPassword,
  getIndustryList,
  addIndustryData,
  editIndustryData,
  deleteIndustryData,
  userLogin,
  searchMyAdvisor,
  searchSavedAdvisorList,
  becomeAnAdvisor,
  deleteProfileData,
}
