// Auth Route

export const LOGIN = "/login"
export const REGISTER = "/register"
export const REGISTER_INFO = "/register-info"
export const LOGOUT = "/logout"
export const EMAILVERIFY = "/email-verification"
export const ForgetPassword = "/forget-password"
export const RESTPASSWORD = "/reset-password"
export const LINKEDINLOGIN = "/login/linkedin"
export const GOOGLELOGIN = "/login/google"
export const USERLOGIN = "/user-login"

// Advisors Route

export const MYADVISOR = "/my-advisor"
export const ADVISORLIST = "/advisor-list"
export const FILTERADVISOR = "/filter-advisor"
export const SAVEADVISOR = "/save-advisor"
export const SAVEDADVISORLIST = "/saved-advisor-list"
export const ADVISORDETAILS = "/advisor-details"
export const MEETINGREQUEST = "/meeting-request-to-advisor"
export const DELETESAVEDADVISOR = "/delete-save-advisor"
export const SEARCHMYADVISOR = "/my-advisor-search"
export const SEARCHSAVEDADVISOR = "/saved-advisor-search"

// Advisor Request

export const ADDREQUEST = "/add-request"
export const REQUESTLIST = "/request-list"
export const REQUEST_SENT = "/sent-request-list"
export const REQUESTACTION = "/respond-to-request"
export const REMOVEREQUEST = "/remove-request"

// Skills

export const SKILLSLIST = "/skill-list"
export const ADDSKILL = "/add-skill"
export const UPDATESKILL = "/update-skill"
export const EDITSKILL = "/edit-skill"
export const DELETESKILL = "/delete-skill"
export const SEARCHSKILL = "/search-skill"

// Profile

export const PROFILE = "/profile"
export const EDITPROFILE = "/edit-profile"

// Users
export const USERLIST = "/user-list"
export const UPDATEADVISORSTATUS = "/update-advisor-status"
export const DELETEUSER = "/delete-user"

// Industry

export const INDUSTRYLIST = "/industry-list"
export const ADDINDUSTRY = "/add-industry"
export const UPDATEINDUSTRY = "/update-industry"
export const EDITINDUSTRY = "/edit-industry"
export const DELETEINDUSTRY = "/delete-industry"
export const SEARCHINDUSTRY = "/search-industry"

// Become Advisor
export const BECOME_AN_ADVISOR = "/become-an-advisor"

// delete advisor
export const DELETE_PROFILE = "/remove-profile-image"
