import { messages } from "common/data"
import {
  ADVISORLIST,
  ADVISORLISTERROR,
  ADVISORLISTSUCCESS,
  MYADVISOR,
  MYADVISORERROR,
  MYADVISORSUCCESS,
  SAVEADVISOR,
  SAVEDADVISOR,
  SAVEDADVISORERROR,
  SAVEDADVISORSUCCESS,
  SAVEADVISORERROR,
  SAVEADVISORSUCCESS,
  DELETESAVEADVISOR,
  DELETESAVEADVISORSUCCESS,
  DELETESAVEADVISORERROR,
  FILTERADVISOR,
  FILTERADVISORSUCCESS,
  FILTERADVISORERROR,
  MEETINGREQUEST,
  MEETINGREQUESTSUCCESS,
  MEETINGREQUESTError,
  JOINREQUEST,
  JOINREQUESTSUCCESS,
  JOINREQUESTError,
  REQUESTLIST,
  REQUESTLISTSUCCESS,
  REQUESTLISTError,
  REQUESTACTION,
  REQUESTACTIONSUCCESS,
  REQUESTACTIONError,
  SEARCHMYADVISOR,
  SEARCHMYADVISORERROR,
  SEARCHMYADVISORSUCCESS,
  SEARCHSAVEDADVISOR,
  SEARCHSAVEDADVISORSUCCESS,
  SEARCHSAVEDADVISORERROR,
  REQUEST_SENT,
  REQUEST_SENT_SUCCESS,
  REQUEST_SENT_FAIL,
  BECOME_ADVISOR,
  BECOME_ADVISOR_SUCCESS,
  BECOME_ADVISOR_FAIL
} from "./actionTypes"

export const myAdvisorList = () => ({
  type: MYADVISOR,
})

export const myAdvisorSuccess = data => ({
  type: MYADVISORSUCCESS,
  payload: data,
})

export const myAdvisorError = error => ({
  type: MYADVISORERROR,
  payload: error,
})

export const searchMyAdvisorList = data => ({
  type: SEARCHMYADVISOR,
  payload: data,
})

export const searchMyAdvisorSuccess = data => ({
  type: SEARCHMYADVISORSUCCESS,
  payload: data,
})

export const searchMyAdvisorError = error => ({
  type: SEARCHSAVEDADVISOR,
  payload: error,
})
export const searchSavedAdvisorList = data => ({
  type: SEARCHSAVEDADVISOR,
  payload: data,
})

export const searchSavedAdvisorSuccess = data => ({
  type: SEARCHSAVEDADVISORERROR,
  payload: data,
})

export const searchSavedAdvisorError = error => ({
  type: SEARCHMYADVISORERROR,
  payload: error,
})

export const advisorsList = () => ({
  type: ADVISORLIST,
})

export const advisorListSuccess = data => ({
  type: ADVISORLISTSUCCESS,
  payload: data,
})

export const advisorListError = error => ({
  type: ADVISORLISTERROR,
  payload: error,
})

export const savedAdvisor = () => ({
  type: SAVEDADVISOR,
})

export const SavedAdvisorSuccess = data => ({
  type: SAVEDADVISORSUCCESS,
  payload: data,
})

export const SavedAdvisorError = error => ({
  type: SAVEDADVISORERROR,
  payload: error,
})

export const saveAdvisor = data => ({
  type: SAVEADVISOR,
  payload: data,
})

export const SaveAdvisorSuccess = data => ({
  type: SAVEADVISORSUCCESS,
  payload: data,
})

export const SaveAdvisorError = error => ({
  type: SAVEADVISORERROR,
  payload: error,
})

export const deleteSavedAdvisor = data => ({
  type: DELETESAVEADVISOR,
  payload: data,
})

export const deleteSavedAdvisorSuccess = msg => ({
  type: DELETESAVEADVISORSUCCESS,
  payload: msg,
})

export const deleteSavedAdvisorError = error => ({
  type: DELETESAVEADVISORERROR,
  payload: error,
})

export const filterAdvisor = data => ({
  type: FILTERADVISOR,
  payload: data,
})

export const filterAdvisorSuccess = data => ({
  type: FILTERADVISORSUCCESS,
  payload: data,
})

export const filterAdvisorError = error => ({
  type: FILTERADVISORERROR,
  payload: error,
})

export const meetingRequest = id => ({
  type: MEETINGREQUEST,
  payload: id,
})

export const meetingRequestSuccess = message => ({
  type: MEETINGREQUESTSUCCESS,
  payload: message,
})

export const meetingRequestError = error => ({
  type: MEETINGREQUESTError,
  payload: error,
})

export const joinRequest = id => ({
  type: JOINREQUEST,
  payload: id,
})

export const joinRequestSuccess = message => ({
  type: JOINREQUESTSUCCESS,
  payload: message,
})

export const joinRequestError = error => ({
  type: JOINREQUESTError,
  payload: error,
})

export const requestList = () => ({
  type: REQUESTLIST,
})

export const requestListSuccess = message => ({
  type: REQUESTLISTSUCCESS,
  payload: message,
})

export const requestListError = error => ({
  type: REQUESTLISTError,
  payload: error,
})

export const requestSent = () => ({
  type: REQUEST_SENT,
})

export const requestSentSuccess = message => ({
  type: REQUEST_SENT_SUCCESS,
  payload: message,
})

export const requestSentError = error => ({
  type: REQUEST_SENT_FAIL,
  payload: error,
})

export const requestAction = data => ({
  type: REQUESTACTION,
  payload: data,
})

export const requestActionSuccess = message => ({
  type: REQUESTACTIONSUCCESS,
  payload: message,
})

export const requestActionError = error => ({
  type: REQUESTACTIONError,
  payload: error,
})

export const becomeAdvisor = () => ({
  type: BECOME_ADVISOR
})

export const becomeAdvisorSuccess = message => ({
  type: BECOME_ADVISOR_SUCCESS,
  payload: message,
})

export const becomeAdvisorError = error => ({
  type: BECOME_ADVISOR_FAIL,
  payload: error,
})
