import {
  ADD_NEW_SKILL,
  ADD_NEW_SKILL_SUCCESS,
  ADD_SNEW_SKILL_ERROR,
  DELETE_SKILL,
  DELETE_SKILL_ERROR,
  DELETE_SKILL_USCCESS,
  EDIT_SKILL,
  EDIT_SKILL_ERROR,
  SKILL_LIST,
  SKILL_LIST_ERROR,
  SKILL_LIST_SUCCESS,
} from "./actionTypes"

export const getSkills = () => ({
  type: SKILL_LIST,
})

export const getSkillSuccess = data => ({
  type: SKILL_LIST_SUCCESS,
  payload: data,
})

export const getSKIllError = error => ({
  type: SKILL_LIST_ERROR,
  payload: error,
})

export const addSkill = (data, history) => ({
  type: ADD_NEW_SKILL,
  payload: { data, history },
})

export const addSkillSuccess = data => ({
  type: ADD_NEW_SKILL_SUCCESS,
  payload: data,
})
export const addSkillError = error => ({
  type: ADD_SNEW_SKILL_ERROR,
  payload: error,
})

export const editSkill = (data, history) => ({
  type: EDIT_SKILL,
  payload: { data, history },
})

export const editSkillSuccess = data => ({
  type: EDIT_SKILL_SUCCESS,
  payload: data,
})
export const editSkillError = error => ({
  type: EDIT_SKILL_ERROR,
  payload: error,
})

export const deleteSkill = data => ({
  type: DELETE_SKILL,
  payload: data,
})

export const deleteSkillSuccess = data => ({
  type: DELETE_SKILL_USCCESS,
  payload: data,
})

export const deleteSkillError = error => ({
  type: DELETE_SKILL_ERROR,
  payload: error,
})
